.about-us {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: calc(100vh - 10rem);
  grid-row-gap: 10rem;
  overflow: hidden;

  @include respond(phone-big) {
    grid-template-rows: max-content;
    grid-row-gap: 8rem;
  }
  @include respond(phone) {
    grid-row-gap: 0rem;
  }

  &__hero {
    padding: 0 2rem 4rem;
    display: flex;
    gap: 2rem;
    background-image: linear-gradient(
      0deg,
      rgba($color-primary-1, 0.3) 85%,
      rgba($color-primary-4, 0.8) 100%,
      transparent 100%
    );

    @include respond(phone-big) {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: max-content max-content;
    }
    @include respond(phone) {
      gap: 0rem;
    }

    &__heading {
      @include respond(tab-med) {
        letter-spacing: 1;
        font-size: 3.2rem;
      }
      @include respond(phone-big) {
        font-size: 4rem;
      }
      @include respond(phone) {
        font-size: 3rem;
      }
    }

    &__img-box {
      flex: 0 0 50%;
      display: grid;
      grid-gap: 1rem;
      padding: 1rem;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: repeat(6, 10vh);

      @include respond(phone-big) {
        grid-row: 2/3;
        padding: 2rem;
        height: 75rem;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(6, 1fr);
      }
      @include respond(phone) {
        height: min-content;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(6, 10vw);
      }
    }
    &__img {
      border-radius: 4px;
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      transition: all 0.3s;

      &:hover {
        transform: scale(1.05);
      }

      &--1 {
        grid-row: 1 / span 4;
        grid-column: 1 / span 2;
      }
      &--2 {
        grid-row: 1 / span 3;
        grid-column: 5 / span 2;
      }
      &--3 {
        grid-row: 4 / span 3;
        grid-column: 5 / span 2;
      }
      &--4 {
        grid-row: 5 / span 2;
        grid-column: 1 / span 4;
      }
      &--5 {
        grid-row: 1 / span 2;
        grid-column: 3 / span 2;
      }
      &--6 {
        grid-row: 3 / span 2;
        grid-column: 3 / span 2;
      }
    }
    &__text-box {
      padding: 4rem;
      display: flex;
      flex-direction: column;
      gap: 2rem;

      @include respond(phone) {
        padding: 2rem;
      }
    }
    &__heading {
      color: $color-primary-17;
    }
    &__text {
      color: $color-primary-14;
    }
  }
  &__faq {
    @include respond(phone) {
      margin-top: 4rem;
    }
  }
}
