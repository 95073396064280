#hero {
  height: 100vh;
  transition: 0.5s;
  display: grid;
  grid-template-columns: 1fr 1fr;
  overflow: hidden;

  @include respond(phone) {
    grid-template-columns: 1fr;
  }
}
.home__hero {
  &__left__heading,
  &__right__heading {
    transition: all 0.3s;
    text-align: center;
    display: inline-block;
    width: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }
  &__right__heading {
    color: $color-primary-1;
    animation: moveInRight 0.75s ease-out 0.5s;
    animation-fill-mode: backwards;

    @include respond(phone) {
      animation: moveInRight 0.75s ease-out 1s;
      animation-fill-mode: backwards;
    }
  }
  &__left__heading {
    animation: moveInLeft 0.75s ease-out 1s;
    animation-fill-mode: backwards;

    @include respond(phone) {
      animation: moveInLeft 0.75s ease-out 0.5s;
      animation-fill-mode: backwards;
    }
  }
  &__left {
    position: relative;
    background-image: linear-gradient(
        to right bottom,
        rgba($color-primary-6, 0.3),
        rgba($color-primary-9, 0.3)
      ),
      url('/src/img/hero/hero-1-2x.webp');
    background-size: auto 90rem;
    background-position: left;

    @media only screen and (min-resolution: 192dpi) and (min-width: 37.5em),
      only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 37.5em),
      only screen and (min-width: 125em) {
      background-image: linear-gradient(
          to right bottom,
          rgba($color-primary-6, 0.3),
          rgba($color-primary-9, 0.3)
        ),
        url('/src/img/hero/hero-1-3x.webp');
    }
  }

  &__right {
    position: relative;
    background-image: linear-gradient(
        to right bottom,
        rgba($color-primary-9, 0.3),
        rgba($color-primary-12, 0.3)
      ),
      url('/src/img/hero/hero-2-2x.webp');
    background-size: auto 90rem;
    background-position: left;

    @media only screen and (min-resolution: 192dpi) and (min-width: 37.5em),
      only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 37.5em),
      only screen and (min-width: 125em) {
      background-image: linear-gradient(
          to right bottom,
          rgba($color-primary-9, 0.3),
          rgba($color-primary-12, 0.3)
        ),
        url('/src/img/hero/hero-2-3x.webp');
    }
  }
  &__left-box,
  &__right-box {
    @include absCenter;
    display: flex;
    gap: 3rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
  }
}

#hero:has(.home__hero__left:hover) {
  grid-template-columns: 2fr 0.5fr;
  .home__hero__right__heading {
    font-size: 4rem;
  }
  .home__hero__left__heading {
    font-size: 8rem;
  }
}

#hero:has(.home__hero__right:hover) {
  grid-template-columns: 0.5fr 2fr;
  .home__hero__left__heading {
    font-size: 4rem;
  }
  .home__hero__right__heading {
    font-size: 8rem;
  }
}

:where(.home__hero__left, .home__hero__right) {
  transition: 0.3s;
}

// hover animation for header
.home__hero__right__heading__text,
.home__hero__left__heading__text {
  display: block;
  width: max-content;
  text-align: center;
}
.home__hero__right__heading__text::after,
.home__hero__left__heading__text::after {
  content: '';
  display: block;
  width: 0;
  height: 3px;
  background: $color-primary-10;
  transition: width 0.5s;
}
.home__hero__right__heading__text:hover:after,
.home__hero__left__heading__text:hover:after {
  width: 100%;
}
@media only screen and (max-width: 56.25em), only screen and (hover: none) {
  #hero:has(.home__hero__left:hover) {
    grid-template-columns: 1fr 1fr;

    @include respond(phone) {
      grid-template-columns: 1fr;
    }
    .home__hero__right__heading {
      font-size: 6rem;

      @include respond(phone-med) {
        font-size: 5.5rem;
      }
      @include respond(phone) {
        font-size: 5rem;
      }
      @include respond(phone-small) {
        font-size: 4.5rem;
      }
      @include respond(phone-smallest) {
        font-size: 4rem;
      }
    }
    .home__hero__left__heading {
      font-size: 6rem;

      @include respond(phone-med) {
        font-size: 5.5rem;
      }
      @include respond(phone) {
        font-size: 5rem;
      }
      @include respond(phone-small) {
        font-size: 4.5rem;
      }
      @include respond(phone-smallest) {
        font-size: 4rem;
      }
    }
  }

  #hero:has(.home__hero__right:hover) {
    grid-template-columns: 1fr 1fr;

    @include respond(phone) {
      grid-template-columns: 1fr;
    }
    .home__hero__left__heading {
      font-size: 6rem;

      @include respond(phone-med) {
        font-size: 5.5rem;
      }
      @include respond(phone) {
        font-size: 5rem;
      }
      @include respond(phone-small) {
        font-size: 4.5rem;
      }
      @include respond(phone-smallest) {
        font-size: 4rem;
      }
    }
    .home__hero__right__heading {
      font-size: 6rem;

      @include respond(phone-med) {
        font-size: 5.5rem;
      }
      @include respond(phone) {
        font-size: 5rem;
      }
      @include respond(phone-small) {
        font-size: 4.5rem;
      }
      @include respond(phone-smallest) {
        font-size: 4rem;
      }
    }
  }

  :where(.home__hero__left, .home__hero__right) {
    transition: 0.3s;
  }

  // hover animation for header
  .home__hero__right__heading__text,
  .home__hero__left__heading__text {
    display: block;
    width: auto;
    text-align: center;
  }
  .home__hero__right__heading__text::after,
  .home__hero__left__heading__text::after {
    content: '';
    display: block;
    width: 0;
    height: 3px;
    background: $color-primary-10;
    transition: width 0.5s;
  }
  .home__hero__right__heading__text:hover:after,
  .home__hero__left__heading__text:hover:after {
    width: 100%;
  }
}
