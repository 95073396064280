.swiper {
  max-width: 90vw;
  max-height: 95vh;
  height: 100%;
  width: 100%;

  &-slide {
    text-align: center;
    font-size: 1.8rem;
    background: #fff;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
