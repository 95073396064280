.popup {
  transition: all 0.3s;

  &__content {
    width: 85vw;
    position: relative;

    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 9999;
    transform: translate(-50%, -50%);
  }

  &__close {
    z-index: 999;
    color: $color-primary-17;
    font-family: inherit;
    position: absolute;
    top: 1rem;
    right: 2rem;
    font-size: 5rem;
    cursor: pointer;
    border: none;
    background: none;

    &:hover {
      color: $color-primary-8;
    }
  }
}

.overlay {
  background-color: rgba($color-primary-16, 0.8);

  z-index: 9998;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @supports (-webkit-backdrop-filter: blur(10px)) or
    (backdrop-filter: blur(10px)) {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba($color-primary-10, 0.1);
  }
}
