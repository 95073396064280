// COLORS
$color-white: #fff;
$color-primary-1: #fbeaea;
$color-primary-2: #f7d6d6;
$color-primary-3: #f3c1c1;
$color-primary-4: #efacad;
$color-primary-5: #eb9898;
$color-primary-6: #e68383;
$color-primary-7: #e26e6f;
$color-primary-8: #de595a;
$color-primary-9: #da4546;
$color-primary-10: #d63031;
$color-primary-11: #c12b2c;
$color-primary-12: #ab2627;
$color-primary-13: #962222;
$color-primary-14: #801d1d;
$color-primary-15: #6b1819;
$color-primary-16: #561314;
$color-primary-17: #400e0f;
$color-primary-18: #2b0a0a;
$color-primary-19: #150505;
$color-black: #000;

// FONT
$default-font-size: 1.8rem;

//Border radius
$default-border-radius: 4rem;
