body {
  font-family: 'Rubik', sans-serif;
  line-height: 1;
  font-weight: 400;
  color: $color-primary-19;
}

.heading-1 {
  font-size: 6rem;
  line-height: 1.2;
  color: $color-white;
  display: inline-block;
  text-align: center;

  @include respond(phone-med) {
    font-size: 5.5rem;
  }
  @include respond(phone) {
    font-size: 5rem;
  }
  @include respond(phone-small) {
    font-size: 4.5rem;
  }
  @include respond(phone-smallest) {
    font-size: 4rem;
  }
}

.heading__group-2 {
  max-width: 76vw;
  margin: 0 auto 6rem auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.heading-2 {
  font-size: 4rem;
  line-height: 1.4;
  letter-spacing: 1.2;
  font-weight: 500;

  @include respond(phone-med) {
    font-size: 3.2rem;
    line-height: 1.2;
  }
  @include respond(phone) {
    font-size: 3rem;
    line-height: 1.2;
  }
}
.heading-3 {
  font-size: 3rem;
  line-height: 1.2;
  font-weight: 600;

  @include respond(tab-med) {
    font-size: 2.4rem;
  }
  @include respond(phone) {
    font-size: 2rem;
  }
}
.heading-4 {
  font-size: 2.4rem;
  line-height: 1.1;
  font-weight: 500;
}
.paragraph {
  font-size: $default-font-size;
  line-height: 1.5;
}

.subheading {
  font-weight: 600;
  letter-spacing: 1.5;
  font-size: 2rem;
  text-transform: uppercase;
  color: $color-primary-10;
  opacity: 0.9;
  // margin-bottom: 1rem;
}
