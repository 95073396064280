.how {
  padding: 6rem 1rem;

  &-box {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 1rem;
  }
  &__item {
    height: 45rem;
    padding: 3rem;
    border-radius: 4px;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    transition: all 0.3s;

    &:hover &__text-box {
      transform: translateY(1rem);
    }
    &:hover &__text-box__number {
      color: $color-primary-1;
    }
    &:hover &__text-box__heading {
      color: $color-primary-1;
    }
    &:hover &__text-box__text {
      transform: translateY(0rem);
    }

    &--1 {
      background-image: linear-gradient(
          to bottom,
          rgba($color-primary-1, 0.05),
          rgba($color-primary-12, 0.8)
        ),
        url('/src/img/how/how-1-2x.webp');

      @media only screen and (min-resolution: 192dpi) and (min-width: 37.5em),
        only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 37.5em),
        only screen and (min-width: 125em) {
        background-image: linear-gradient(
            to right bottom,
            rgba($color-primary-1, 0.05),
            rgba($color-primary-12, 0.8)
          ),
          url('/src/img/how/how-1-3x.webp');
      }
    }
    &--2 {
      background-image: linear-gradient(
          to bottom,
          rgba($color-primary-1, 0.05),
          rgba($color-primary-12, 0.8)
        ),
        url('/src/img/how/how-2-2x.webp');

      @media only screen and (min-resolution: 192dpi) and (min-width: 37.5em),
        only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 37.5em),
        only screen and (min-width: 125em) {
        background-image: linear-gradient(
            to right bottom,
            rgba($color-primary-1, 0.05),
            rgba($color-primary-12, 0.8)
          ),
          url('/src/img/how/how-2-3x.webp');
      }
    }
    &--3 {
      background-image: linear-gradient(
          to bottom,
          rgba($color-primary-1, 0.05),
          rgba($color-primary-12, 0.8)
        ),
        url('/src/img/how/how-3-2x.webp');

      @media only screen and (min-resolution: 192dpi) and (min-width: 37.5em),
        only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 37.5em),
        only screen and (min-width: 125em) {
        background-image: linear-gradient(
            to right bottom,
            rgba($color-primary-1, 0.05),
            rgba($color-primary-12, 0.8)
          ),
          url('/src/img/how/how-3-3x.webp');
      }
    }
    &--4 {
      background-image: linear-gradient(
          to bottom,
          rgba($color-primary-1, 0.05),
          rgba($color-primary-12, 0.8)
        ),
        url('/src/img/how/how-4-2x.webp');

      @media only screen and (min-resolution: 192dpi) and (min-width: 37.5em),
        only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 37.5em),
        only screen and (min-width: 125em) {
        background-image: linear-gradient(
            to right bottom,
            rgba($color-primary-1, 0.05),
            rgba($color-primary-12, 0.8)
          ),
          url('/src/img/how/how-4-3x.webp');
      }
    }

    &__text-box {
      display: flex;
      flex-direction: column;
      gap: 3rem;
      transform: translateY(20rem);
      transition: all 0.5s;

      &__number {
        font-size: 8rem;
        font-weight: 600;
        color: $color-primary-3;
        transition: all 0.3s;
      }
      &__heading {
        color: $color-primary-1;
        transition: all 0.3s;
      }
      &__text {
        color: $color-primary-2;
        transition: all 0.3s;
        transform: translateY(10rem);

        a,
        a:link,
        a:visited {
          text-decoration: none;
          font-weight: 600;
          color: $color-primary-1;
          background-color: inherit;
        }
      }
    }
  }
  @media only screen and (max-width: 56.25em), only screen and (hover: none) {
    &-box {
      padding: 2rem;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 2rem;

      @include respond(phone) {
        padding: 0rem;
        grid-gap: 0.5rem;
      }

      @include respond(phone-small) {
        padding: 0rem;
        grid-template-columns: 1fr;
      }
    }

    &__item {
      height: 50rem;

      &:hover &__text-box {
        transform: translateY(1rem);
      }
      &:hover &__text-box__number {
        color: rgba($color-primary-17, 0.7);
      }
      &:hover &__text-box__heading {
        color: $color-primary-17;
      }
      &:hover &__text-box__text {
        transform: translateY(0rem);
      }

      &--1 {
        background-image: linear-gradient(
            to bottom,
            rgba($color-primary-1, 0.9),
            rgba($color-primary-4, 0.8)
          ),
          url('/src/img/how/how-1-3x.webp');
      }
      &--2 {
        background-image: linear-gradient(
            to bottom,
            rgba($color-primary-1, 0.9),
            rgba($color-primary-4, 0.8)
          ),
          url('/src/img/how/how-2-3x.webp');
      }
      &--3 {
        background-image: linear-gradient(
            to bottom,
            rgba($color-primary-1, 0.9),
            rgba($color-primary-4, 0.8)
          ),
          url('/src/img/how/how-3-3x.webp');
      }
      &--4 {
        background-image: linear-gradient(
            to bottom,
            rgba($color-primary-1, 0.9),
            rgba($color-primary-4, 0.8)
          ),
          url('/src/img/how/how-4-3x.webp');
      }

      &__text-box {
        transform: translateY(0rem);
        &__number {
          color: rgba($color-primary-17, 0.7);
        }
        &__heading {
          color: $color-primary-17;
          transition: all 0.3s;
          @include respond(phone-small) {
            font-size: 3rem;
          }
        }

        &__text {
          color: $color-primary-15;
          font-weight: 400;
          transform: translateY(0rem);
          @include respond(phone-small) {
            font-size: 2rem;
          }

          a,
          a:link,
          a:visited {
            text-decoration: none;
            font-weight: 600;
            color: $color-primary-12;
            background-color: inherit;
          }
        }
      }
    }
  }
}
