.cards {
  padding: 4rem 6rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  grid-gap: 4rem;
  margin-bottom: 8rem;

  @include respond(phone) {
    grid-gap: 6rem;
    margin-bottom: 0rem;
  }
}
.card {
  box-shadow: 0 1rem 2rem rgba($color-primary-15, 0.3);
  border-radius: 4px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 3.5rem;
  overflow: hidden;
  position: relative;

  &__img-box {
    height: 36rem;
    width: 100%;
    grid-row: 1 / 2;
    grid-column: 1 / -1;
    z-index: 1;
    position: relative;
    @include respond(phone) {
      height: 40rem;
    }
  }
  &__img {
    border-radius: 4px;
    width: 100%;
  }

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    justify-self: end;
    margin: 1rem;
    transition: all 0.4s;
    // cursor: none;

    &__icon {
      fill: $color-primary-7;
      height: 5rem;
      width: 5rem;
      transition: all 0.4s;
      cursor: pointer;

      &:hover {
        fill: $color-primary-10;
        transform: scale(1.1);
      }
      &:active {
        fill: $color-primary-9;
        transform: scale(1.05);
      }
    }
  }

  &__name {
    grid-row: 1 / 2;
    grid-column: 1 / -1;
    justify-self: center;
    align-self: end;
    z-index: 3;
    width: 80%;
    font-family: 'Rubik', sans-serif;
    font-size: $default-font-size;
    text-align: center;
    padding: 1.25rem;
    border-radius: $default-border-radius;
    background-color: $color-primary-10;
    color: $color-primary-1;
    font-weight: 500;
    transform: translateY(50%);
    transition: all 0.3s;
  }
  &__text-box {
    grid-row: 2 / 3;
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 1.6rem;
    padding: 2rem;

    &__item {
      font-size: 1.5rem;
      display: flex;
      align-items: center;
    }
    &__icon {
      // background-color: $color-primary-1;
      border-radius: 50%;
      fill: $color-primary-10;
      height: 2rem;
      width: 2rem;
      margin-right: 1rem;
    }
  }
}
.card:hover .card__link__icon {
  animation: jello-vertical 0.9s both;
  animation-delay: 0.3s;
}
