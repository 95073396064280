.accordion-box {
  width: 60vw;
  margin: 4rem auto;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  transition: all 0.4s ease;

  @include respond(phone-big) {
    width: 80vw;
  }
  @include respond(phone-small) {
    width: 95vw;
  }
}

.accordion {
  transition: all 0.4s ease;

  &__item {
    box-shadow: 0 0 3.2rem rgba($color-primary-15, 0.1);
    border-radius: $default-border-radius;
    overflow: hidden;

    transition: all 0.4s ease;

    &__btn {
      background-color: $color-white;

      font-family: inherit;
      cursor: pointer;
      padding: 2rem;
      width: 100%;
      border: none;
      text-align: left;
      outline: none;
      color: $color-primary-19;

      transition: all 0.4s ease;

      &:hover {
        background-color: rgba($color-primary-8, 0.1);
      }
      &:active {
        background-color: $color-white;
      }
    }

    &__visible {
      display: grid;
      grid-template-columns: auto 1fr auto;
      grid-column-gap: 2.4rem;
    }

    &__number,
    &__text {
      font-size: 2.4rem;
      font-weight: 500;
      transition: all 0.4s ease;

      @include respond(phone) {
        font-size: 2rem;
      }
      @include respond(phone-smallest) {
        font-size: 1.8rem;
      }
    }

    &__number {
      color: rgba($color-primary-15, 0.5);
      transition: all 0.4s ease;
    }
    &__icon {
      width: 2.4rem;
      height: 2.4rem;
      fill: $color-primary-8;
      opacity: 0.5s ease-in-out;
      cursor: pointer;
      transition: all 0.4s ease;

      &:hover {
        transform: scale(1.1);
      }
    }

    &__hidden {
      padding: 0 2rem;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease-out;
      p {
        opacity: 0;
        font-size: $default-font-size;
        line-height: 1.6;
        margin-bottom: 2.4rem;
        padding: 1.2rem 1.6rem;
        font-family: inherit;
        transition: all 0.3s;
      }
    }
  }
}
.open {
  border-top: 4px solid $color-primary-8;
}
.open .accordion__item__hidden p {
  opacity: 1;
}
.open .accordion__item__number,
.open .accordion__item__text {
  color: $color-primary-8;
  opacity: 1;
}
.open .accordion__item__icon--closed {
  transform: rotate(180deg);
}
.open .accordion__item__btn:hover {
  background-color: $color-white;
}
