.features {
  border-top: 2px solid rgba($color-primary-10, 0.2);
  border-bottom: 2px solid rgba($color-primary-10, 0.2);
  padding: 6rem 0;
  &--box {
    display: grid;
    grid-template-columns: repeat(3, 30rem);
    grid-gap: 6rem;
    justify-content: center;

    @include respond(phone-big) {
      grid-template-columns: repeat(2, 30rem);
      grid-gap: 4rem;
    }
    @include respond(phone) {
      grid-template-columns: repeat(2, 21rem);
      grid-gap: 1.4rem;
    }
    @include respond(phone-smallest) {
      grid-template-columns: repeat(1, 65vw);
      grid-gap: 1rem;
    }
  }

  &__item {
    box-shadow: 0 2rem 4rem rgba($color-primary-10, 0.2);
    font-size: $default-font-size;
    line-height: 1.4;
    padding: 2rem;
    text-align: center;
    border-radius: 2rem;
    transition: transform 0.3s;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: space-between;

    @include respond(phone) {
      padding: 1.4rem;
      gap: 1.6rem;
    }

    &__text {
      text-align: center;

      @include respond(phone) {
        font-size: 1.4rem;
      }
    }

    &__icon {
      height: 6rem;
      width: 6rem;
      fill: rgba($color-primary-10, 1);
      margin-bottom: 0.5rem;
      display: inline-block;

      @include respond(tab-port) {
        margin-bottom: 0;
        height: 5rem;
        width: 5rem;
      }
      @include respond(phone) {
        height: 3.2rem;
        width: 3.2rem;
      }
    }

    &:hover {
      // animation: wobble-hor-bottom 0.8s both;
      transform: translateY(-1.5rem) scale(1.03);
    }
  }
}
