.stone {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 100vh;
  grid-row-gap: 10rem;
  overflow: hidden;

  &__hero {
    background-image: linear-gradient(
        to left,
        rgba($color-primary-10, 0.6),
        rgba($color-primary-1, 0.5)
      ),
      url('/src/img/stone/hero/hero-1-2x.webp');
    background-size: cover;
    background-position: center;

    @media only screen and (min-resolution: 192dpi) and (min-width: 37.5em),
      only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 37.5em),
      only screen and (min-width: 125em) {
      background-image: linear-gradient(
          to left,
          rgba($color-primary-10, 0.6),
          rgba($color-primary-1, 0.5)
        ),
        url('/src/img/stone/hero/hero-1-3x.webp');
    }

    &__text-box {
      width: 80%;

      @include absCenter;
      display: flex;
      flex-direction: column;
      gap: 3rem;

      h1 {
        animation: slide-in-bottom 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94)
          backwards;
      }
    }
    &__btn {
      animation: slide-in-bottom 0.7s cubic-bezier(0.25, 0.46, 0.45, 0.94)
        backwards;
      animation-delay: 0.7s;
    }
  }

  &__gallery {
    a,
    a:link,
    a:visited {
      margin: 2rem auto;
      display: block;
      width: max-content;
    }
  }
}
