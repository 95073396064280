.composition-box {
  background-color: $color-white;
  max-width: 100vw;
  display: grid;
  /*grid-template-columns: repeat(8, 1fr);
      grid-template-rows: repeat(7, 5vw);*/
  grid-template: repeat(7, 5vw) / repeat(8, 1fr);

  grid-gap: 1.5rem;
  padding: 1.5rem;
  position: relative;

  @include respond(phone-med) {
    grid-template: repeat(14, 8vw) / repeat(8, 1fr);
    grid-gap: 0.7rem;
  }

  &__item {
    transition: all 0.3s;

    &:hover {
      transform: scale(1.05);
    }

    &--1 {
      grid-row: 1 / span 2;
      grid-column: 1 / span 2;

      @include respond(phone-med) {
        grid-row: 1 / span 4;
        grid-column: 1 / span 4;
      }
    }

    &--2 {
      grid-row: 1 / span 3;
      grid-column: 3 / span 3;

      @include respond(phone-med) {
        grid-row: 1 / span 2;
        grid-column: 5 / span 2;
      }
    }

    &--3 {
      grid-row: 1 / span 2;
      grid-column: 6 / 7;

      @include respond(phone-med) {
        grid-row: 1 / span 2;
        grid-column: 7 / span 2;
      }
    }

    &--4 {
      grid-row: 1 / span 2;
      grid-column: 7 / -1;

      @include respond(phone-med) {
        grid-row: 3 / span 4;
        grid-column: 5 / span 4;
      }
    }

    &--5 {
      grid-row: 3 / span 3;
      grid-column: 1 / span 2;

      @include respond(phone-med) {
        grid-row: 5 / span 2;
        grid-column: 1 / span 2;
      }
    }

    &--6 {
      grid-row: 4 / span 2;
      grid-column: 3 / span 2;

      @include respond(phone-med) {
        grid-row: 5 / span 4;
        grid-column: 3 / span 2;
      }
    }

    &--7 {
      grid-row: 4 / 5;
      grid-column: 5 / 6;

      @include respond(phone-med) {
        grid-row: 7 / span 4;
        grid-column: 1 / span 2;
      }
    }

    &--8 {
      grid-row: 3 / span 2;
      grid-column: 6 / span 2;

      @include respond(phone-med) {
        grid-row: 7 / span 2;
        grid-column: 5 / span 2;
      }
    }

    &--9 {
      grid-row: 3 / span 3;
      grid-column: 8 / -1;

      @include respond(phone-med) {
        grid-row: 11 / span 2;
        grid-column: 1 / span 2;
      }
    }

    &--10 {
      grid-row: 6 / span 2;
      grid-column: 1 / 2;

      @include respond(phone-med) {
        grid-row: 9 / span 4;
        grid-column: 3 / span 4;
      }
    }

    &--11 {
      grid-row: 6 / span 2;
      grid-column: 2 / span 2;

      @include respond(phone-med) {
        grid-row: 7 / span 4;
        grid-column: 7 / span 2;
      }
    }

    &--12 {
      grid-row: 6 / span 2;
      grid-column: 4 / 5;

      @include respond(phone-med) {
        grid-row: 11 / span 2;
        grid-column: 7 / span 2;
      }
    }

    &--13 {
      grid-row: 5 / span 3;
      grid-column: 5 / span 3;

      @include respond(phone-med) {
        grid-row: 13 / span 2;
        grid-column: 1 / span 4;
      }
    }

    &--14 {
      grid-row: 6 / span 2;
      grid-column: 8 / -1;

      @include respond(phone-med) {
        grid-row: 13 / span 2;
        grid-column: 5 / span 4;
      }
    }
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    border-radius: 4px;
    box-shadow: 0 1rem 1rem rgba($color-primary-19, 0.3);
  }
}
