.footer {
  padding: 6rem 6rem 2rem 6rem;
  display: grid;
  grid-row-gap: 4rem;
  grid-template-columns: repeat(3, 1fr);
  background-color: $color-primary-1;
  border-top: 2px solid $color-primary-2;

  @include respond(phone-med) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include respond(phone) {
    grid-template-columns: 1fr;
  }
}

.social {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  justify-content: center;
  align-items: center;

  @include respond(phone-med) {
    grid-row: 2/3;
    grid-column: 1/3;
    flex-direction: row;
    justify-content: center;
    padding-top: 4rem;
    border-top: 1px solid $color-primary-2;
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    align-items: center;
    justify-content: center;
  }

  @include respond(phone) {
    grid-row: 3/4;
    grid-column: 1/2;
    grid-template-columns: 1fr;
    justify-self: stretch;
    grid-row-gap: 3rem;
  }

  &__logo {
    display: inline-block;

    @include respond(phone-med) {
      justify-self: center;
      align-self: center;
    }

    &__img {
      width: 25em;

      @include respond(tab-port) {
        width: 20rem;
      }
      @include respond(phone-med) {
        width: 17rem;
      }
      @include respond(phone) {
        width: 15rem;
        margin-bottom: 0;
      }
      @include respond(phone-small) {
        width: 12rem;
      }
    }
  }
  &__links {
    align-self: center;
    list-style: none;
    display: flex;
    gap: 2rem;
    align-items: center;

    @include respond(phone-med) {
      // align-self: center;
      justify-self: center;
    }

    @include respond(phone-small) {
      align-items: center;
      justify-content: center;
    }
    li {
      z-index: 99;
      fill: $color-primary-10;
    }

    &__link:link,
    &__link:visited {
      fill: inherit;
      display: inline-block;
      text-decoration: none;
      transition: all 0.3s;
    }
    &__link:hover {
      transform: scale(1.2);
      fill: $color-primary-12;
    }
    &__link:active {
      transform: scale(1.05);
    }

    &__icon {
      height: 4rem;
      width: 4rem;
      fill: inherit;
    }
  }

  &__copyright {
    align-self: start;
    color: rgba($color-primary-10, 0.4);

    font-size: 1.4rem;
    line-height: 1.6;
    margin-top: auto;
  }
}

.contacts {
  color: $color-primary-15;
  justify-self: center;
  display: flex;
  flex-direction: column;
  gap: 3rem;

  @include respond(phone) {
    grid-row: 1/2;
    grid-column: 1/2;
    justify-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__heading {
    color: $color-primary-17;
    font-size: 3rem;
    font-weight: 600;
    font-family: inherit;
  }
  &__contact {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
    font-style: normal;
    font-size: 1.8rem;
    line-height: 1.6;

    @include respond(phone) {
      justify-content: center;
      align-items: center;
    }

    &__link-box {
      display: inline-block;
      width: max-content;
    }

    &__link:link,
    &__link:visited {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 2rem;
      text-decoration: none;
      color: inherit;
      transition: all 0.5s;
    }

    &__link-box::after {
      content: '';
      display: block;
      width: 0;
      height: 2px;
      background: $color-primary-10;
      transition: width 0.3s;
    }

    &__link-box:hover::after {
      width: 100%;
    }

    &__link__icon {
      height: 3rem;
      width: 3rem;
      fill: $color-primary-10;
    }
  }
}

.nav {
  justify-self: center;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  color: $color-primary-19;

  @include respond(phone-med) {
    align-self: center;
  }

  @include respond(phone) {
    padding-top: 4rem;
    border-top: 1px solid $color-primary-2;
    justify-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @include respond(phone-small) {
    padding-top: 4rem;
    border-top: 1px solid $color-primary-2;
    justify-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__nav {
    color: $color-primary-14;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 3rem;

    @include respond(phone) {
      justify-content: center;
      align-items: center;
    }

    &__link:link,
    &__link:visited {
      padding: 0.5rem;
      border: 2px solid transparent;
      border-radius: 4px;
      text-decoration: none;
      font-size: $default-font-size;
      color: inherit;
      transition: all 0.3s;
    }

    &__link:hover {
      background-color: $color-primary-1;
      color: $color-primary-10;
      border: 2px solid $color-primary-10;
    }
  }
}
