.header {
  z-index: 2;
  height: 10rem;
  color: $color-primary-1;
  background-image: linear-gradient(
    rgba($color-primary-10, 0.8) 0%,
    rgba($color-primary-4, 0.8) 100%
  );
  display: flex;
  align-items: center;
  gap: 2rem;

  @include respond(phone-big) {
    height: 8rem;
  }

  &__logo-box {
    margin-left: 2rem;
    height: 10rem;
    animation: none;

    @include respond(phone-big) {
      height: 8rem;
    }

    &:hover {
      animation: pulse 1s infinite;
    }
  }
  &__logo__img {
    height: 100%;
  }

  &__nav {
    flex: 0.93;

    @include respond(phone-big) {
      display: none;
    }

    &__list {
      display: flex;
      gap: 2rem;
      justify-content: left;
      align-items: center;
      list-style: none;
    }
    &__item {
      cursor: pointer;
      transition: all 0.3s;

      //ANIMATION
      -webkit-animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
        both;
      animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

      &:nth-child(1) {
        animation-delay: 0.25s;
      }
      &:nth-child(2) {
        animation-delay: 0.35s;
      }
      &:nth-child(3) {
        animation-delay: 0.45s;
      }
      &:nth-child(4) {
        animation-delay: 0.55s;
      }
      &:nth-child(5) {
        animation-delay: 0.65s;
      }
      &:nth-child(6) {
        animation-delay: 0.75s;
      }

      ///////////////////////////////
      &:hover {
        transform: translateY(-2px);
      }
      &:active {
        transform: translateY(-1px);
      }
      &--active:hover {
        transform: translateY(0px);
      }

      &__link:link,
      &__link:visited {
        background-color: rgba($color-primary-10, 0.7);
        font-size: 2.4rem;
        color: inherit;
        text-decoration: none;
        cursor: pointer;
        padding: 1rem 2rem;
        border-radius: 5rem;
        transition: all 0.3s;
        box-shadow: 0 0.5rem 1rem rgba($color-primary-19, 0.1);
        border: 2px solid transparent;

        @include respond(tab-med) {
          font-size: 2rem;
        }
      }
      &__link:hover {
        background-color: rgba($color-primary-10, 1);

        box-shadow: 0 1rem 2rem rgba($color-primary-19, 0.2);
        border: 2px solid $color-primary-1;
      }
      &__link:active {
        background-color: rgba($color-primary-10, 0.7);

        box-shadow: 0 0.6rem 1.2rem rgba($color-primary-19, 0.2);
        color: $color-primary-2;
        border: 2px solid $color-primary-2;
      }

      &__link--active:link,
      &__link--active:visited {
        color: $color-primary-14;
        background-color: $color-primary-1;
        border: 2px solid $color-primary-10;
      }
      &__link--active:hover {
        transform: translateY(0px);
        border: 2px solid $color-primary-10;
        box-shadow: 0 0.5rem 1rem rgba($color-primary-19, 0.1);
      }
    }
  }

  &--1 {
    background-image: none;
    background-color: transparent;
    z-index: 222;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

@media only screen and (max-width: 56.25em), only screen and (hover: none) {
  .header {
    &__logo-box {
      animation: pulse 1s infinite;
    }

    &__nav {
      &__item {
        &__link:link,
        &__link:visited {
          background-color: rgba($color-primary-10, 1);
        }
        &__link--active:link,
        &__link--active:visited {
          color: $color-primary-14;
          background-color: $color-primary-1;
        }
      }
    }
  }
}
