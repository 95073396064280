.success {
  height: 100vh;
  position: relative;
  &-box {
    z-index: 9999;
    @include absCenter;
    width: 60rem;
    background-color: $color-primary-8;
    border-radius: $default-border-radius;
    padding: 4rem;
    display: flex;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &--icon {
      width: 10rem;
      height: 10rem;
      fill: $color-primary-8;
      background-color: $color-primary-2;
      padding: 2rem;
      border-radius: 50%;
    }

    &__text {
      color: $color-primary-2;
      font-size: 2rem;
      line-height: 1.7;
    }

    &__link:link,
    &__link:visited {
      color: $color-primary-10;
      background-color: $color-primary-2;
    }
  }
}
