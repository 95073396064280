@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-25rem);
  }

  80% {
    transform: translateX(1rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(25rem);
  }

  80% {
    transform: translateX(-1rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(10rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInTop {
  0% {
    opacity: 0;
    transform: translateY(-10rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveLeft {
  0% {
    transform: translateX(-100rem);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes moveRight {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-100rem);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  70% {
    transform: scale(1.06);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes pulseTopBtn {
  0% {
    transform: scale(1)rotate(180deg);
  }

  70% {
    transform: scale(1.06)rotate(180deg);
  }

  100% {
    transform: scale(1)rotate(180deg);
  }
}

.bounce-in-top {
  animation: 1.1s both bounce-in-top;
}

@keyframes bounce-in-top {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: translateY(-500px);
  }

  38% {
    opacity: 1;
    animation-timing-function: ease-out;
    transform: translateY(0);
  }

  55% {
    animation-timing-function: ease-in;
    transform: translateY(-65px);
  }

  72% {
    animation-timing-function: ease-out;
    transform: translateY(0);
  }

  81% {
    animation-timing-function: ease-in;
    transform: translateY(-28px);
  }

  90% {
    animation-timing-function: ease-out;
    transform: translateY(0);
  }

  95% {
    animation-timing-function: ease-in;
    transform: translateY(-8px);
  }

  100% {
    animation-timing-function: ease-out;
    transform: translateY(0);
  }
}

.slide-in-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-top;
}

@keyframes slide-in-top {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.tracking-in-expand {
  animation: .5s steps(2, end) both tracking-in-expand;
}

@keyframes tracking-in-expand {
  0% {
    letter-spacing: -.5em;
    opacity: 0;
  }

  40% {
    opacity: .6;
  }

  100% {
    opacity: 1;
  }
}

.slide-in-left {
  animation: .75s cubic-bezier(.25, .46, .45, .94) both slide-in-left;
}

@keyframes slide-in-left {
  0% {
    opacity: 0;
    transform: translateX(-1000px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.slide-in-right {
  animation: .75s cubic-bezier(.25, .46, .45, .94) both slide-in-right;
}

@keyframes slide-in-right {
  0% {
    opacity: 0;
    transform: translateX(1000px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.slide-in-bottom {
  animation: 1s cubic-bezier(.25, .46, .45, .94) backwards slide-in-bottom;
}

@keyframes slide-in-bottom {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.slide-in-blurred-left {
  animation: 1s cubic-bezier(.23, 1, .32, 1) both slide-in-blurred-left;
}

@keyframes slide-in-blurred-left {
  0% {
    transform-origin: 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
    transform: translateX(-100px)scaleX(2.5)scaleY(.2);
  }

  100% {
    transform-origin: 50%;
    -webkit-filter: blur();
    filter: blur();
    opacity: 1;
    transform: translateX(0)scaleY(1)scaleX(1);
  }
}

.slide-in-blurred-right {
  animation: 1s cubic-bezier(.23, 1, .32, 1) both slide-in-blurred-right;
}

@keyframes slide-in-blurred-right {
  0% {
    transform-origin: 0%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
    transform: translateX(100px)scaleX(2.5)scaleY(.2);
  }

  100% {
    transform-origin: 50%;
    -webkit-filter: blur();
    filter: blur();
    opacity: 1;
    transform: translateX(0)scaleY(1)scaleX(1);
  }
}

.slide-in-blurred-bottom {
  animation: .6s cubic-bezier(.23, 1, .32, 1) both slide-in-blurred-bottom;
}

@keyframes slide-in-blurred-bottom {
  0% {
    transform-origin: 50% 100%;
    -webkit-filter: blur(40px);
    filter: blur(40px);
    opacity: 0;
    transform: translateY(100px)scaleY(2.5)scaleX(.2);
  }

  100% {
    transform-origin: 50%;
    -webkit-filter: blur();
    filter: blur();
    opacity: 1;
    transform: translateY(0)scaleY(1)scaleX(1);
  }
}

.scale-in-hor-left {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both scale-in-hor-left;
}

@keyframes scale-in-hor-left {
  0% {
    transform-origin: 0 0;
    opacity: 1;
    transform: scaleX(0);
  }

  100% {
    transform-origin: 0 0;
    opacity: 1;
    transform: scaleX(1);
  }
}

.flip-in-ver-right {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-in-ver-right;
}

@keyframes flip-in-ver-right {
  0% {
    opacity: 0;
    transform: rotateY(-80deg);
  }

  100% {
    opacity: 1;
    transform: rotateY(0);
  }
}

.slide-in-elliptic-top-fwd {
  animation: .7s cubic-bezier(.25, .46, .45, .94) both slide-in-elliptic-top-fwd;
}

@keyframes slide-in-elliptic-top-fwd {
  0% {
    transform-origin: 50% 100%;
    opacity: 0;
    transform: translateY(-600px)rotateX(-30deg)scale(0);
  }

  100% {
    transform-origin: 50% 1400px;
    opacity: 1;
    transform: translateY(0)rotateX(0)scale(1);
  }
}

.roll-in-bottom {
  animation: .6s ease-out both roll-in-bottom;
}

@keyframes roll-in-bottom {
  0% {
    opacity: 0;
    transform: translateY(800px)rotate(540deg);
  }

  100% {
    opacity: 1;
    transform: translateY(0)rotate(0);
  }
}

.bounce-in-bottom {
  animation: 1.1s both bounce-in-bottom;
}

@keyframes bounce-in-bottom {
  0% {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: translateY(500px);
  }

  38% {
    opacity: 1;
    animation-timing-function: ease-out;
    transform: translateY(0);
  }

  55% {
    animation-timing-function: ease-in;
    transform: translateY(65px);
  }

  72% {
    animation-timing-function: ease-out;
    transform: translateY(0);
  }

  81% {
    animation-timing-function: ease-in;
    transform: translateY(28px);
  }

  90% {
    animation-timing-function: ease-out;
    transform: translateY(0);
  }

  95% {
    animation-timing-function: ease-in;
    transform: translateY(8px);
  }

  100% {
    animation-timing-function: ease-out;
    transform: translateY(0);
  }
}

.wobble-hor-bottom {
  animation: .8s both wobble-hor-bottom;
}

@keyframes wobble-hor-bottom {
  0%, 100% {
    transform-origin: 50%;
    transform: translateX(0%);
  }

  15% {
    transform: translateX(-30px)rotate(-6deg);
  }

  30% {
    transform: translateX(15px)rotate(6deg);
  }

  45% {
    transform: translateX(-15px)rotate(-3.6deg);
  }

  60% {
    transform: translateX(9px)rotate(2.4deg);
  }

  75% {
    transform: translateX(-6px)rotate(-1.2deg);
  }
}

.flip-in-hor-top {
  animation: .5s cubic-bezier(.25, .46, .45, .94) both flip-in-hor-top;
}

@keyframes flip-in-hor-top {
  0% {
    opacity: 0;
    transform: rotateX(-80deg);
  }

  100% {
    opacity: 1;
    transform: rotateX(0);
  }
}

.tilt-in-bottom-2 {
  animation: .6s cubic-bezier(.25, .46, .45, .94) both tilt-in-bottom-2;
}

@keyframes tilt-in-bottom-2 {
  0% {
    opacity: 0;
    transform: rotateY(-30deg)translateY(300px)skewY(30deg);
  }

  100% {
    opacity: 1;
    transform: rotateY(0)translateY(0)skewY(0);
  }
}

.fade-in-bottom {
  animation: .6s cubic-bezier(.39, .575, .565, 1) both fade-in-bottom;
}

@keyframes fade-in-bottom {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.jello-vertical {
  animation: .9s both jello-vertical;
}

@keyframes jello-vertical {
  0% {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(.75, 1.25, 1);
  }

  40% {
    transform: scale3d(1.25, .75, 1);
  }

  50% {
    transform: scale3d(.85, 1.15, 1);
  }

  65% {
    transform: scale3d(1.05, .95, 1);
  }

  75% {
    transform: scale3d(.95, 1.05, 1);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

*, :after, :before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
  font-size: 62.5%;
}

@media only screen and (min-width: 112.5em) {
  html {
    font-size: 75%;
  }
}

@media only screen and (max-width: 81.25em) {
  html {
    font-size: 56.25%;
  }
}

@media only screen and (max-width: 62.5em) {
  html {
    font-size: 50%;
  }
}

body {
  box-sizing: border-box;
}

::selection {
  color: #f7d6d6;
  background-color: #d63031;
}

img {
  transition: filter .5s;
}

.section {
  transition: transform 1s, opacity 1s;
}

.section--hidden {
  opacity: 0;
  transform: translateY(8rem);
}

body {
  color: #150505;
  font-family: Rubik, sans-serif;
  font-weight: 400;
  line-height: 1;
}

.heading-1 {
  color: #fff;
  text-align: center;
  font-size: 6rem;
  line-height: 1.2;
  display: inline-block;
}

@media only screen and (max-width: 46.875em) {
  .heading-1 {
    font-size: 5.5rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .heading-1 {
    font-size: 5rem;
  }
}

@media only screen and (max-width: 28.125em) {
  .heading-1 {
    font-size: 4.5rem;
  }
}

@media only screen and (max-width: 21.875em) {
  .heading-1 {
    font-size: 4rem;
  }
}

.heading__group-2 {
  max-width: 76vw;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 0 auto 6rem;
  display: flex;
}

.heading-2 {
  letter-spacing: 1.2px;
  font-size: 4rem;
  font-weight: 500;
  line-height: 1.4;
}

@media only screen and (max-width: 46.875em) {
  .heading-2 {
    font-size: 3.2rem;
    line-height: 1.2;
  }
}

@media only screen and (max-width: 37.5em) {
  .heading-2 {
    font-size: 3rem;
    line-height: 1.2;
  }
}

.heading-3 {
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.2;
}

@media only screen and (max-width: 71.875em) {
  .heading-3 {
    font-size: 2.4rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .heading-3 {
    font-size: 2rem;
  }
}

.heading-4 {
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 1.1;
}

.paragraph {
  font-size: 1.8rem;
  line-height: 1.5;
}

.subheading {
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #d63031;
  opacity: .9;
  font-size: 2rem;
  font-weight: 600;
}

.u-center-text {
  text-align: center !important;
}

.u-margin-right {
  margin-right: 2rem !important;
}

.u-margin-bottom-small {
  margin-bottom: 1.5rem !important;
}

.u-margin-bottom-medium {
  margin-bottom: 4rem !important;
}

@media only screen and (max-width: 62.5em) {
  .u-margin-bottom-medium {
    margin-bottom: 3rem !important;
  }
}

.u-margin-bottom-big {
  margin-bottom: 8rem !important;
}

@media only screen and (max-width: 62.5em) {
  .u-margin-bottom-big {
    margin-bottom: 5rem !important;
  }
}

@media only screen and (max-width: 37.5em) {
  .u-margin-bottom-big {
    margin-bottom: 2rem !important;
  }
}

.u-margin-top-big {
  margin-top: 8rem !important;
}

.u-margin-top-huge {
  margin-top: 10rem !important;
}

.hidden {
  visibility: hidden;
  opacity: 0;
}

.lazy-img {
  filter: blur(5px);
}

.accordion-box {
  width: 60vw;
  flex-direction: column;
  gap: 2.4rem;
  margin: 4rem auto;
  transition: all .4s;
  display: flex;
}

@media only screen and (max-width: 56.25em) {
  .accordion-box {
    width: 80vw;
  }
}

@media only screen and (max-width: 28.125em) {
  .accordion-box {
    width: 95vw;
  }
}

.accordion {
  transition: all .4s;
}

.accordion__item {
  border-radius: 4rem;
  transition: all .4s;
  overflow: hidden;
  box-shadow: 0 0 3.2rem #6b18191a;
}

.accordion__item__btn {
  cursor: pointer;
  width: 100%;
  text-align: left;
  color: #150505;
  background-color: #fff;
  border: none;
  outline: none;
  padding: 2rem;
  font-family: inherit;
  transition: all .4s;
}

.accordion__item__btn:hover {
  background-color: #de595a1a;
}

.accordion__item__btn:active {
  background-color: #fff;
}

.accordion__item__visible {
  grid-column-gap: 2.4rem;
  grid-template-columns: auto 1fr auto;
  display: grid;
}

.accordion__item__number, .accordion__item__text {
  font-size: 2.4rem;
  font-weight: 500;
  transition: all .4s;
}

@media only screen and (max-width: 37.5em) {
  .accordion__item__number, .accordion__item__text {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 21.875em) {
  .accordion__item__number, .accordion__item__text {
    font-size: 1.8rem;
  }
}

.accordion__item__number {
  color: #6b181980;
  transition: all .4s;
}

.accordion__item__icon {
  width: 2.4rem;
  height: 2.4rem;
  fill: #de595a;
  opacity: .5s ease-in-out;
  cursor: pointer;
  transition: all .4s;
}

.accordion__item__icon:hover {
  transform: scale(1.1);
}

.accordion__item__hidden {
  max-height: 0;
  padding: 0 2rem;
  transition: max-height .3s ease-out;
  overflow: hidden;
}

.accordion__item__hidden p {
  opacity: 0;
  margin-bottom: 2.4rem;
  padding: 1.2rem 1.6rem;
  font-family: inherit;
  font-size: 1.8rem;
  line-height: 1.6;
  transition: all .3s;
}

.open {
  border-top: 4px solid #de595a;
}

.open .accordion__item__hidden p {
  opacity: 1;
}

.open .accordion__item__number, .open .accordion__item__text {
  color: #de595a;
  opacity: 1;
}

.open .accordion__item__icon--closed {
  transform: rotate(180deg);
}

.open .accordion__item__btn:hover {
  background-color: #fff;
}

.btn, .btn:link, .btn:visited {
  text-transform: uppercase;
  color: #150505;
  cursor: pointer;
  background-color: #d63031;
  border: 2px solid #0000;
  border-radius: 4rem;
  padding: 1rem 2rem;
  font-family: inherit;
  font-size: 1.8rem;
  font-weight: 600;
  text-decoration: none;
  transition: all .3s;
  display: inline-block;
  position: relative;
}

.btn:hover {
  color: #400e0f;
  background-color: #f7d6d6;
  border: 2px solid #d63031;
  transform: scale(1.05);
}

.btn:hover:after {
  opacity: 0;
  background-color: #fff;
  transform: scaleX(1.4)scaleY(2);
}

.btn:active {
  outline: none;
  transform: scale(1.02);
}

.btn:focus {
  outline: none;
}

.btn--hero:link, .btn--hero:visited {
  color: #f7d6d6;
  background-color: #da4546;
}

.btn--hero:hover {
  color: #d63031;
  background-color: #f7d6d6;
}

.btn--popup:link, .btn--popup:visited {
  width: max-content;
  color: #ab2627;
  background-color: #f7d6d6;
  align-self: center;
  text-decoration: none;
}

.btn--popup:hover, .btn--popup:active {
  transform: scale(1);
}

.btn:after {
  content: "";
  height: 100%;
  width: 100%;
  z-index: -1;
  border-radius: 10rem;
  transition: all .4s;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.btn--top {
  height: 5rem;
  width: 5rem;
  fill: #d63031;
  cursor: pointer;
  background-color: #f7d6d6;
  border-radius: 50%;
  transform: rotate(180deg);
}

.btn--top:hover {
  animation: 1s infinite pulseTopBtn;
  transform: rotate(180deg);
}

.btn--top--link:link, .btn--top--link:visited {
  z-index: 999999999;
  color: inherit;
  text-decoration: none;
  position: fixed;
  bottom: 4rem;
  left: 2rem;
}

.btn--tab {
  cursor: pointer;
  background-color: #fbeaea;
  border: none;
  border-radius: 2rem;
  padding: 1rem 1.6rem;
  font-family: inherit;
  font-size: 2rem;
  font-weight: 500;
  text-decoration: none;
  transition: all .3s;
  display: inline-block;
  box-shadow: 0 .5rem 1rem #d630314d;
}

@media only screen and (max-width: 37.5em) {
  .btn--tab {
    font-size: 1.6rem;
  }
}

.btn--tab:hover {
  color: #fbeaea;
  background-color: #d63031;
}

.btn--tab--stone {
  width: max-content;
}

.cards {
  grid-gap: 4rem;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  margin-bottom: 8rem;
  padding: 4rem 6rem;
  display: grid;
}

@media only screen and (max-width: 37.5em) {
  .cards {
    grid-gap: 6rem;
    margin-bottom: 0;
  }
}

.card {
  grid-row-gap: 3.5rem;
  border-radius: 4px;
  grid-template-columns: repeat(2, 1fr);
  display: grid;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1rem 2rem #6b18194d;
}

.card__img-box {
  height: 36rem;
  width: 100%;
  z-index: 1;
  grid-area: 1 / 1 / 2 / -1;
  position: relative;
}

@media only screen and (max-width: 37.5em) {
  .card__img-box {
    height: 40rem;
  }
}

.card__img {
  width: 100%;
  border-radius: 4px;
}

.card__link {
  z-index: 2;
  justify-self: end;
  margin: 1rem;
  transition: all .4s;
  position: absolute;
  top: 0;
  left: 0;
}

.card__link__icon {
  fill: #e26e6f;
  height: 5rem;
  width: 5rem;
  cursor: pointer;
  transition: all .4s;
}

.card__link__icon:hover {
  fill: #d63031;
  transform: scale(1.1);
}

.card__link__icon:active {
  fill: #da4546;
  transform: scale(1.05);
}

.card__name {
  z-index: 3;
  width: 80%;
  text-align: center;
  color: #fbeaea;
  background-color: #d63031;
  border-radius: 4rem;
  grid-area: 1 / 1 / 2 / -1;
  place-self: end center;
  padding: 1.25rem;
  font-family: Rubik, sans-serif;
  font-size: 1.8rem;
  font-weight: 500;
  transition: all .3s;
  transform: translateY(50%);
}

.card__text-box {
  grid-row-gap: 1.6rem;
  grid-area: 2 / 1 / 3 / -1;
  grid-template-columns: repeat(2, 1fr);
  padding: 2rem;
  display: grid;
}

.card__text-box__item {
  align-items: center;
  font-size: 1.5rem;
  display: flex;
}

.card__text-box__icon {
  fill: #d63031;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  margin-right: 1rem;
}

.card:hover .card__link__icon {
  animation: .9s .3s both jello-vertical;
}

.composition-box {
  max-width: 100vw;
  grid-gap: 1.5rem;
  background-color: #fff;
  grid-template: repeat(7, 5vw) / repeat(8, 1fr);
  padding: 1.5rem;
  display: grid;
  position: relative;
}

@media only screen and (max-width: 46.875em) {
  .composition-box {
    grid-gap: .7rem;
    grid-template: repeat(14, 8vw) / repeat(8, 1fr);
  }
}

.composition-box__item {
  transition: all .3s;
}

.composition-box__item:hover {
  transform: scale(1.05);
}

.composition-box__item--1 {
  grid-area: 1 / 1 / span 2 / span 2;
}

@media only screen and (max-width: 46.875em) {
  .composition-box__item--1 {
    grid-area: 1 / 1 / span 4 / span 4;
  }
}

.composition-box__item--2 {
  grid-area: 1 / 3 / span 3 / span 3;
}

@media only screen and (max-width: 46.875em) {
  .composition-box__item--2 {
    grid-area: 1 / 5 / span 2 / span 2;
  }
}

.composition-box__item--3 {
  grid-area: 1 / 6 / span 2 / 7;
}

@media only screen and (max-width: 46.875em) {
  .composition-box__item--3 {
    grid-area: 1 / 7 / span 2 / span 2;
  }
}

.composition-box__item--4 {
  grid-area: 1 / 7 / span 2 / -1;
}

@media only screen and (max-width: 46.875em) {
  .composition-box__item--4 {
    grid-area: 3 / 5 / span 4 / span 4;
  }
}

.composition-box__item--5 {
  grid-area: 3 / 1 / span 3 / span 2;
}

@media only screen and (max-width: 46.875em) {
  .composition-box__item--5 {
    grid-area: 5 / 1 / span 2 / span 2;
  }
}

.composition-box__item--6 {
  grid-area: 4 / 3 / span 2 / span 2;
}

@media only screen and (max-width: 46.875em) {
  .composition-box__item--6 {
    grid-area: 5 / 3 / span 4 / span 2;
  }
}

.composition-box__item--7 {
  grid-area: 4 / 5 / 5 / 6;
}

@media only screen and (max-width: 46.875em) {
  .composition-box__item--7 {
    grid-area: 7 / 1 / span 4 / span 2;
  }
}

.composition-box__item--8 {
  grid-area: 3 / 6 / span 2 / span 2;
}

@media only screen and (max-width: 46.875em) {
  .composition-box__item--8 {
    grid-area: 7 / 5 / span 2 / span 2;
  }
}

.composition-box__item--9 {
  grid-area: 3 / 8 / span 3 / -1;
}

@media only screen and (max-width: 46.875em) {
  .composition-box__item--9 {
    grid-area: 11 / 1 / span 2 / span 2;
  }
}

.composition-box__item--10 {
  grid-area: 6 / 1 / span 2 / 2;
}

@media only screen and (max-width: 46.875em) {
  .composition-box__item--10 {
    grid-area: 9 / 3 / span 4 / span 4;
  }
}

.composition-box__item--11 {
  grid-area: 6 / 2 / span 2 / span 2;
}

@media only screen and (max-width: 46.875em) {
  .composition-box__item--11 {
    grid-area: 7 / 7 / span 4 / span 2;
  }
}

.composition-box__item--12 {
  grid-area: 6 / 4 / span 2 / 5;
}

@media only screen and (max-width: 46.875em) {
  .composition-box__item--12 {
    grid-area: 11 / 7 / span 2 / span 2;
  }
}

.composition-box__item--13 {
  grid-area: 5 / 5 / span 3 / span 3;
}

@media only screen and (max-width: 46.875em) {
  .composition-box__item--13 {
    grid-area: 13 / 1 / span 2 / span 4;
  }
}

.composition-box__item--14 {
  grid-area: 6 / 8 / span 2 / -1;
}

@media only screen and (max-width: 46.875em) {
  .composition-box__item--14 {
    grid-area: 13 / 5 / span 2 / span 4;
  }
}

.composition-box__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
  display: block;
  box-shadow: 0 1rem 1rem #1505054d;
}

.cta {
  background-image: linear-gradient(#fff, #eb9898, #fbeaea);
  padding: 15rem 10rem;
}

@media only screen and (max-width: 56.25em) {
  .cta {
    padding: 15rem 4rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .cta {
    padding: 15rem 2rem;
  }
}

.book {
  background-image: linear-gradient(105deg, #fbeaeacc 0% 50%, #0000 80%), url("cta-2x.1965cbe1.webp");
  background-position: center;
  background-size: cover;
  border: 4px solid #e68383;
  border-radius: 4rem;
  padding: 2rem;
  box-shadow: 0 1.5rem 4rem #56131466;
}

@media only screen and (min-resolution: 192dpi) and (min-width: 37.5em), only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 37.5em), only screen and (min-width: 125em) {
  .book {
    background-image: linear-gradient(105deg, #fbeaeacc 0% 50%, #0000 80%), url("cta-3x.bcdb1d5f.webp");
  }
}

@media only screen and (max-width: 81.25em) {
  .book {
    background-image: linear-gradient(105deg, #fbeaeacc 0% 65%, #0000 90%), url("cta-2x.1965cbe1.webp");
    background-size: cover;
  }
}

@media only screen and (min-resolution: 192dpi) and (min-width: 37.5em), only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 37.5em), only screen and (min-width: 125em) {
  .book {
    background-image: linear-gradient(105deg, #fbeaeacc 0% 65%, #0000 90%), url("cta-3x.bcdb1d5f.webp");
    background-size: cover;
  }
}

@media only screen and (max-width: 62.5em) {
  .book {
    background-image: linear-gradient(to right, #fbeaeacc 0% 100%), url("cta-3x.bcdb1d5f.webp");
  }
}

.book__form {
  width: 50%;
  padding: 6rem;
}

@media only screen and (max-width: 81.25em) {
  .book__form {
    width: 65%;
    padding: 5rem;
  }
}

@media only screen and (max-width: 62.5em) {
  .book__form {
    width: 100%;
    padding: 4rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .book__form {
    padding: 2rem;
  }
}

.form__group:not(:last-child) {
  margin-bottom: 2rem;
}

.form__input {
  color: inherit;
  width: 90%;
  background-color: #ffffffe6;
  border: none;
  border-bottom: 3px solid #0000;
  border-radius: 4rem;
  padding: 1.5rem 2rem;
  font-family: inherit;
  font-size: 1.5rem;
  transition: all .3s;
  display: block;
}

@media only screen and (max-width: 62.5em) {
  .form__input {
    width: 100%;
  }
}

.form__input:focus {
  border-bottom: 3px solid #efacad;
  outline: none;
  box-shadow: 0 1rem 2rem #d630311a;
}

.form__input:focus:invalid {
  border-bottom: 3px solid #c12b2c;
}

.form__input::-webkit-input-placeholder {
  color: #eb9898;
}

.form__label {
  color: #150505;
  margin-top: .7rem;
  margin-left: 2rem;
  font-size: 1.2rem;
  font-weight: 700;
  transition: all .3s;
  display: block;
}

.form__input:placeholder-shown + .form__label {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-4rem);
}

.featured-in {
  border-top: 2px solid #fbeaea;
  border-bottom: 2px solid #fbeaea;
  padding: 8rem 4rem;
}

.featured-in__box {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

.featured-in__box h5 {
  text-transform: uppercase;
  color: #e68383;
  text-align: center;
  align-self: center;
  margin-bottom: 4rem;
  font-size: 2.4rem;
}

@media only screen and (max-width: 37.5em) {
  .featured-in__box h5 {
    margin-bottom: 2rem;
    font-size: 2rem;
  }
}

.featured-in__logos {
  justify-content: space-around;
  margin-bottom: 4rem;
  display: flex;
}

@media only screen and (max-width: 62.5em) {
  .featured-in__logos {
    justify-content: space-between;
  }
}

@media only screen and (max-width: 37.5em) {
  .featured-in__logos {
    grid-gap: 2rem;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;
    display: grid;
  }
}

.featured-in__logos img {
  height: 7rem;
  filter: brightness(70%);
  opacity: .7;
  transition: all .3s;
}

@media only screen and (max-width: 71.875em) {
  .featured-in__logos img {
    height: 6rem;
  }
}

@media only screen and (max-width: 56.25em) {
  .featured-in__logos img {
    filter: brightness(70%);
    height: 4rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .featured-in__logos img {
    height: 4.5rem;
  }
}

.featured-in__logos img:hover {
  filter: brightness(40%);
  opacity: 1;
  transform: scale(1.1);
}

@media only screen and (max-width: 37.5em) {
  .featured-in__logo {
    justify-self: center;
  }
}

.features {
  border-top: 2px solid #d6303133;
  border-bottom: 2px solid #d6303133;
  padding: 6rem 0;
}

.features--box {
  grid-gap: 6rem;
  grid-template-columns: repeat(3, 30rem);
  justify-content: center;
  display: grid;
}

@media only screen and (max-width: 56.25em) {
  .features--box {
    grid-gap: 4rem;
    grid-template-columns: repeat(2, 30rem);
  }
}

@media only screen and (max-width: 37.5em) {
  .features--box {
    grid-gap: 1.4rem;
    grid-template-columns: repeat(2, 21rem);
  }
}

@media only screen and (max-width: 21.875em) {
  .features--box {
    grid-gap: 1rem;
    grid-template-columns: repeat(1, 65vw);
  }
}

.features__item {
  text-align: center;
  border-radius: 2rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
  font-size: 1.8rem;
  line-height: 1.4;
  transition: transform .3s;
  display: flex;
  box-shadow: 0 2rem 4rem #d6303133;
}

@media only screen and (max-width: 37.5em) {
  .features__item {
    gap: 1.6rem;
    padding: 1.4rem;
  }
}

.features__item__text {
  text-align: center;
}

@media only screen and (max-width: 37.5em) {
  .features__item__text {
    font-size: 1.4rem;
  }
}

.features__item__icon {
  height: 6rem;
  width: 6rem;
  fill: #d63031;
  margin-bottom: .5rem;
  display: inline-block;
}

@media only screen and (max-width: 62.5em) {
  .features__item__icon {
    height: 5rem;
    width: 5rem;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 37.5em) {
  .features__item__icon {
    height: 3.2rem;
    width: 3.2rem;
  }
}

.features__item:hover {
  transform: translateY(-1.5rem)scale(1.03);
}

.popup {
  transition: all .3s;
}

.popup__content {
  width: 85vw;
  z-index: 9999;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.popup__close {
  z-index: 999;
  color: #400e0f;
  cursor: pointer;
  background: none;
  border: none;
  font-family: inherit;
  font-size: 5rem;
  position: absolute;
  top: 1rem;
  right: 2rem;
}

.popup__close:hover {
  color: #de595a;
}

.overlay {
  z-index: 9998;
  width: 100%;
  height: 100%;
  background-color: #561314cc;
  position: fixed;
  top: 0;
  left: 0;
}

@supports ((-webkit-backdrop-filter: blur(10px))) or ((-webkit-backdrop-filter: blur(10px)) or (backdrop-filter: blur(10px))) {
  .overlay {
    -webkit-backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: #d630311a;
  }
}

.swiper {
  max-width: 90vw;
  max-height: 95vh;
  height: 100%;
  width: 100%;
}

.swiper-slide {
  text-align: center;
  background: #fff;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  display: flex;
}

.swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.gallery-popup {
  width: auto;
  height: auto;
  z-index: 111111;
  background-color: #00000080;
  position: fixed;
  top: 50%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

.gallery-popup__close {
  z-index: 999;
  color: #400e0f;
  cursor: pointer;
  background: none;
  border: none;
  font-family: inherit;
  font-size: 5rem;
  position: absolute;
  top: 1rem;
  right: 2rem;
}

.gallery-popup__close:hover {
  color: #de595a;
}

.cell {
  height: 11rem;
  position: relative;
}

.services {
  max-width: 90vw;
  border-radius: 4rem;
  margin: 6rem auto 0;
  box-shadow: 0 .5rem 4rem 3rem #d630311a;
}

@media only screen and (max-width: 37.5em) {
  .services {
    max-width: 80vw;
    display: flex;
  }
}

.services__tab-container {
  height: 4rem;
  justify-content: center;
  gap: 2rem;
  display: flex;
}

@media only screen and (max-width: 37.5em) {
  .services__tab-container {
    height: auto;
    flex-direction: column;
  }
}

.services__tab-container--1 {
  gap: 3rem;
}

.services__tab {
  transform: translate(0, -50%)rotate(0);
}

@media only screen and (max-width: 37.5em) {
  .services__tab {
    width: 12rem;
    transform-origin: center;
    position: absolute;
    top: 50%;
    left: -100%;
    transform: translate(-50%, -50%)rotate(-90deg);
  }
}

.services__tab--active {
  color: #fbeaea;
  background-color: #da4546;
  transform: translate(0%, -66%)rotate(0);
  box-shadow: 0 1rem 2rem #d6303180;
}

@media only screen and (max-width: 37.5em) {
  .services__tab--active {
    transform-origin: center;
    position: absolute;
    top: 50%;
    left: -100%;
    transform: translate(-58%, -50%)rotate(-90deg);
  }
}

.services__content {
  padding: 2rem 7rem 7rem;
  font-size: 1.8rem;
  display: none;
}

@media only screen and (max-width: 56.25em) {
  .services__content {
    padding: 2rem 4rem 4rem;
  }
}

.services__content--active {
  gap: 3rem;
  display: flex;
}

@media only screen and (max-width: 37.5em) {
  .services__content--active {
    height: 85 rem;
    flex-direction: column;
  }
}

.services__text-box {
  flex-direction: column;
  flex-grow: 2;
  gap: 2rem;
  display: flex;
}

.services__header {
  align-self: center;
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 1.4;
}

.services__img-box {
  height: 100%;
  width: 100%;
  align-self: center;
}

@media only screen and (max-width: 37.5em) {
  .services__img-box {
    max-height: 50%;
    flex-grow: 1;
  }
}

.services__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  transition: all .3s;
  display: flex;
  box-shadow: 0 1rem 4rem #d6303180;
}

.services__img:hover {
  transform: scale(1.1);
}

.services__img--2 svg {
  fill: #e68383;
}

.services__img--3 svg {
  fill: #de595a;
}

.services__content p {
  grid-column: 2;
  line-height: 1.3;
}

.footer {
  grid-row-gap: 4rem;
  background-color: #fbeaea;
  border-top: 2px solid #f7d6d6;
  grid-template-columns: repeat(3, 1fr);
  padding: 6rem 6rem 2rem;
  display: grid;
}

@media only screen and (max-width: 46.875em) {
  .footer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 37.5em) {
  .footer {
    grid-template-columns: 1fr;
  }
}

.social {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  display: flex;
}

@media only screen and (max-width: 46.875em) {
  .social {
    border-top: 1px solid #f7d6d6;
    flex-direction: row;
    grid-area: 2 / 1 / 3 / 3;
    grid-template-columns: 1fr 1fr 2fr;
    justify-content: center;
    align-items: center;
    padding-top: 4rem;
    display: grid;
  }
}

@media only screen and (max-width: 37.5em) {
  .social {
    grid-row-gap: 3rem;
    grid-area: 3 / 1 / 4 / 2;
    grid-template-columns: 1fr;
    justify-self: stretch;
  }
}

.social__logo {
  display: inline-block;
}

@media only screen and (max-width: 46.875em) {
  .social__logo {
    place-self: center;
  }
}

.social__logo__img {
  width: 25em;
}

@media only screen and (max-width: 62.5em) {
  .social__logo__img {
    width: 20rem;
  }
}

@media only screen and (max-width: 46.875em) {
  .social__logo__img {
    width: 17rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .social__logo__img {
    width: 15rem;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 28.125em) {
  .social__logo__img {
    width: 12rem;
  }
}

.social__links {
  align-self: center;
  align-items: center;
  gap: 2rem;
  list-style: none;
  display: flex;
}

@media only screen and (max-width: 46.875em) {
  .social__links {
    justify-self: center;
  }
}

@media only screen and (max-width: 28.125em) {
  .social__links {
    justify-content: center;
    align-items: center;
  }
}

.social__links li {
  z-index: 99;
  fill: #d63031;
}

.social__links__link:link, .social__links__link:visited {
  fill: inherit;
  text-decoration: none;
  transition: all .3s;
  display: inline-block;
}

.social__links__link:hover {
  fill: #ab2627;
  transform: scale(1.2);
}

.social__links__link:active {
  transform: scale(1.05);
}

.social__links__icon {
  height: 4rem;
  width: 4rem;
  fill: inherit;
}

.social__copyright {
  color: #d6303166;
  align-self: start;
  margin-top: auto;
  font-size: 1.4rem;
  line-height: 1.6;
}

.contacts {
  color: #6b1819;
  flex-direction: column;
  justify-self: center;
  gap: 3rem;
  display: flex;
}

@media only screen and (max-width: 37.5em) {
  .contacts {
    flex-direction: column;
    grid-area: 1 / 1 / 2 / 2;
    justify-content: center;
    justify-self: stretch;
    align-items: center;
    display: flex;
  }
}

.contacts__heading {
  color: #400e0f;
  font-family: inherit;
  font-size: 3rem;
  font-weight: 600;
}

.contacts__contact {
  flex-direction: column;
  gap: 2.4rem;
  font-size: 1.8rem;
  font-style: normal;
  line-height: 1.6;
  display: flex;
}

@media only screen and (max-width: 37.5em) {
  .contacts__contact {
    justify-content: center;
    align-items: center;
  }
}

.contacts__contact__link-box {
  width: max-content;
  display: inline-block;
}

.contacts__contact__link:link, .contacts__contact__link:visited {
  color: inherit;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  text-decoration: none;
  transition: all .5s;
  display: flex;
}

.contacts__contact__link-box:after {
  content: "";
  width: 0;
  height: 2px;
  background: #d63031;
  transition: width .3s;
  display: block;
}

.contacts__contact__link-box:hover:after {
  width: 100%;
}

.contacts__contact__link__icon {
  height: 3rem;
  width: 3rem;
  fill: #d63031;
}

.nav {
  color: #150505;
  flex-direction: column;
  justify-self: center;
  gap: 2rem;
  display: flex;
}

@media only screen and (max-width: 46.875em) {
  .nav {
    align-self: center;
  }
}

@media only screen and (max-width: 37.5em) {
  .nav {
    border-top: 1px solid #f7d6d6;
    flex-direction: column;
    justify-content: center;
    justify-self: stretch;
    align-items: center;
    padding-top: 4rem;
    display: flex;
  }
}

@media only screen and (max-width: 28.125em) {
  .nav {
    border-top: 1px solid #f7d6d6;
    flex-direction: column;
    justify-content: center;
    justify-self: stretch;
    align-items: center;
    padding-top: 4rem;
    display: flex;
  }
}

.nav__nav {
  color: #801d1d;
  flex-direction: column;
  gap: 3rem;
  list-style: none;
  display: flex;
}

@media only screen and (max-width: 37.5em) {
  .nav__nav {
    justify-content: center;
    align-items: center;
  }
}

.nav__nav__link:link, .nav__nav__link:visited {
  color: inherit;
  border: 2px solid #0000;
  border-radius: 4px;
  padding: .5rem;
  font-size: 1.8rem;
  text-decoration: none;
  transition: all .3s;
}

.nav__nav__link:hover {
  color: #d63031;
  background-color: #fbeaea;
  border: 2px solid #d63031;
}

.header {
  z-index: 2;
  height: 10rem;
  color: #fbeaea;
  background-image: linear-gradient(#d63031cc 0%, #efacadcc 100%);
  align-items: center;
  gap: 2rem;
  display: flex;
}

@media only screen and (max-width: 56.25em) {
  .header {
    height: 8rem;
  }
}

.header__logo-box {
  height: 10rem;
  margin-left: 2rem;
  animation: none;
}

@media only screen and (max-width: 56.25em) {
  .header__logo-box {
    height: 8rem;
  }
}

.header__logo-box:hover {
  animation: 1s infinite pulse;
}

.header__logo__img {
  height: 100%;
}

.header__nav {
  flex: .93;
}

@media only screen and (max-width: 56.25em) {
  .header__nav {
    display: none;
  }
}

.header__nav__list {
  justify-content: left;
  align-items: center;
  gap: 2rem;
  list-style: none;
  display: flex;
}

.header__nav__item {
  cursor: pointer;
  transition: all .3s;
  animation: .5s cubic-bezier(.25, .46, .45, .94) both slide-in-top;
}

.header__nav__item:nth-child(1) {
  animation-delay: .25s;
}

.header__nav__item:nth-child(2) {
  animation-delay: .35s;
}

.header__nav__item:nth-child(3) {
  animation-delay: .45s;
}

.header__nav__item:nth-child(4) {
  animation-delay: .55s;
}

.header__nav__item:nth-child(5) {
  animation-delay: .65s;
}

.header__nav__item:nth-child(6) {
  animation-delay: .75s;
}

.header__nav__item:hover {
  transform: translateY(-2px);
}

.header__nav__item:active {
  transform: translateY(-1px);
}

.header__nav__item--active:hover {
  transform: translateY(0);
}

.header__nav__item__link:link, .header__nav__item__link:visited {
  color: inherit;
  cursor: pointer;
  background-color: #d63031b3;
  border: 2px solid #0000;
  border-radius: 5rem;
  padding: 1rem 2rem;
  font-size: 2.4rem;
  text-decoration: none;
  transition: all .3s;
  box-shadow: 0 .5rem 1rem #1505051a;
}

@media only screen and (max-width: 71.875em) {
  .header__nav__item__link:link, .header__nav__item__link:visited {
    font-size: 2rem;
  }
}

.header__nav__item__link:hover {
  background-color: #d63031;
  border: 2px solid #fbeaea;
  box-shadow: 0 1rem 2rem #15050533;
}

.header__nav__item__link:active {
  color: #f7d6d6;
  background-color: #d63031b3;
  border: 2px solid #f7d6d6;
  box-shadow: 0 .6rem 1.2rem #15050533;
}

.header__nav__item__link--active:link, .header__nav__item__link--active:visited {
  color: #801d1d;
  background-color: #fbeaea;
  border: 2px solid #d63031;
}

.header__nav__item__link--active:hover {
  border: 2px solid #d63031;
  transform: translateY(0);
  box-shadow: 0 .5rem 1rem #1505051a;
}

.header--1 {
  z-index: 222;
  width: 100%;
  background-color: #0000;
  background-image: none;
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (max-width: 56.25em), only screen and (hover: none) {
  .header__logo-box {
    animation: 1s infinite pulse;
  }

  .header__nav__item__link:link, .header__nav__item__link:visited {
    background-color: #d63031;
  }

  .header__nav__item__link--active:link, .header__nav__item__link--active:visited {
    color: #801d1d;
    background-color: #fbeaea;
  }
}

#hero {
  height: 100vh;
  grid-template-columns: 1fr 1fr;
  transition: all .5s;
  display: grid;
  overflow: hidden;
}

@media only screen and (max-width: 37.5em) {
  #hero {
    grid-template-columns: 1fr;
  }
}

.home__hero__left__heading, .home__hero__right__heading {
  text-align: center;
  width: max-content;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  transition: all .3s;
  display: flex;
}

.home__hero__right__heading {
  color: #fbeaea;
  animation: .75s ease-out .5s backwards moveInRight;
}

@media only screen and (max-width: 37.5em) {
  .home__hero__right__heading {
    animation: .75s ease-out 1s backwards moveInRight;
  }
}

.home__hero__left__heading {
  animation: .75s ease-out 1s backwards moveInLeft;
}

@media only screen and (max-width: 37.5em) {
  .home__hero__left__heading {
    animation: .75s ease-out .5s backwards moveInLeft;
  }
}

.home__hero__left {
  background-image: linear-gradient(to bottom right, #e683834d, #da45464d), url("hero-1-2x.8bd11bcf.webp");
  background-position: 0;
  background-size: auto 90rem;
  position: relative;
}

@media only screen and (min-resolution: 192dpi) and (min-width: 37.5em), only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 37.5em), only screen and (min-width: 125em) {
  .home__hero__left {
    background-image: linear-gradient(to bottom right, #e683834d, #da45464d), url("hero-1-3x.4901db21.webp");
  }
}

.home__hero__right {
  background-image: linear-gradient(to bottom right, #da45464d, #ab26274d), url("hero-2-2x.59e2bbba.webp");
  background-position: 0;
  background-size: auto 90rem;
  position: relative;
}

@media only screen and (min-resolution: 192dpi) and (min-width: 37.5em), only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 37.5em), only screen and (min-width: 125em) {
  .home__hero__right {
    background-image: linear-gradient(to bottom right, #da45464d, #ab26274d), url("hero-2-3x.659576fd.webp");
  }
}

.home__hero__left-box, .home__hero__right-box {
  width: 90%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 3rem;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#hero:has(.home__hero__left:hover) {
  grid-template-columns: 2fr .5fr;
}

#hero:has(.home__hero__left:hover) .home__hero__right__heading {
  font-size: 4rem;
}

#hero:has(.home__hero__left:hover) .home__hero__left__heading {
  font-size: 8rem;
}

#hero:has(.home__hero__right:hover) {
  grid-template-columns: .5fr 2fr;
}

#hero:has(.home__hero__right:hover) .home__hero__left__heading {
  font-size: 4rem;
}

#hero:has(.home__hero__right:hover) .home__hero__right__heading {
  font-size: 8rem;
}

:where(.home__hero__left, .home__hero__right) {
  transition: all .3s;
}

.home__hero__right__heading__text, .home__hero__left__heading__text {
  width: max-content;
  text-align: center;
  display: block;
}

.home__hero__right__heading__text:after, .home__hero__left__heading__text:after {
  content: "";
  width: 0;
  height: 3px;
  background: #d63031;
  transition: width .5s;
  display: block;
}

.home__hero__right__heading__text:hover:after, .home__hero__left__heading__text:hover:after {
  width: 100%;
}

@media only screen and (max-width: 56.25em), only screen and (hover: none) {
  #hero:has(.home__hero__left:hover) {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 56.25em) and (max-width: 37.5em), only screen and (hover: none) and (max-width: 37.5em) {
  #hero:has(.home__hero__left:hover) {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 56.25em), only screen and (hover: none) {
  #hero:has(.home__hero__left:hover) .home__hero__right__heading {
    font-size: 6rem;
  }
}

@media only screen and (max-width: 56.25em) and (max-width: 46.875em), only screen and (hover: none) and (max-width: 46.875em) {
  #hero:has(.home__hero__left:hover) .home__hero__right__heading {
    font-size: 5.5rem;
  }
}

@media only screen and (max-width: 56.25em) and (max-width: 37.5em), only screen and (hover: none) and (max-width: 37.5em) {
  #hero:has(.home__hero__left:hover) .home__hero__right__heading {
    font-size: 5rem;
  }
}

@media only screen and (max-width: 56.25em) and (max-width: 28.125em), only screen and (hover: none) and (max-width: 28.125em) {
  #hero:has(.home__hero__left:hover) .home__hero__right__heading {
    font-size: 4.5rem;
  }
}

@media only screen and (max-width: 56.25em) and (max-width: 21.875em), only screen and (hover: none) and (max-width: 21.875em) {
  #hero:has(.home__hero__left:hover) .home__hero__right__heading {
    font-size: 4rem;
  }
}

@media only screen and (max-width: 56.25em), only screen and (hover: none) {
  #hero:has(.home__hero__left:hover) .home__hero__left__heading {
    font-size: 6rem;
  }
}

@media only screen and (max-width: 56.25em) and (max-width: 46.875em), only screen and (hover: none) and (max-width: 46.875em) {
  #hero:has(.home__hero__left:hover) .home__hero__left__heading {
    font-size: 5.5rem;
  }
}

@media only screen and (max-width: 56.25em) and (max-width: 37.5em), only screen and (hover: none) and (max-width: 37.5em) {
  #hero:has(.home__hero__left:hover) .home__hero__left__heading {
    font-size: 5rem;
  }
}

@media only screen and (max-width: 56.25em) and (max-width: 28.125em), only screen and (hover: none) and (max-width: 28.125em) {
  #hero:has(.home__hero__left:hover) .home__hero__left__heading {
    font-size: 4.5rem;
  }
}

@media only screen and (max-width: 56.25em) and (max-width: 21.875em), only screen and (hover: none) and (max-width: 21.875em) {
  #hero:has(.home__hero__left:hover) .home__hero__left__heading {
    font-size: 4rem;
  }
}

@media only screen and (max-width: 56.25em), only screen and (hover: none) {
  #hero:has(.home__hero__right:hover) {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 56.25em) and (max-width: 37.5em), only screen and (hover: none) and (max-width: 37.5em) {
  #hero:has(.home__hero__right:hover) {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 56.25em), only screen and (hover: none) {
  #hero:has(.home__hero__right:hover) .home__hero__left__heading {
    font-size: 6rem;
  }
}

@media only screen and (max-width: 56.25em) and (max-width: 46.875em), only screen and (hover: none) and (max-width: 46.875em) {
  #hero:has(.home__hero__right:hover) .home__hero__left__heading {
    font-size: 5.5rem;
  }
}

@media only screen and (max-width: 56.25em) and (max-width: 37.5em), only screen and (hover: none) and (max-width: 37.5em) {
  #hero:has(.home__hero__right:hover) .home__hero__left__heading {
    font-size: 5rem;
  }
}

@media only screen and (max-width: 56.25em) and (max-width: 28.125em), only screen and (hover: none) and (max-width: 28.125em) {
  #hero:has(.home__hero__right:hover) .home__hero__left__heading {
    font-size: 4.5rem;
  }
}

@media only screen and (max-width: 56.25em) and (max-width: 21.875em), only screen and (hover: none) and (max-width: 21.875em) {
  #hero:has(.home__hero__right:hover) .home__hero__left__heading {
    font-size: 4rem;
  }
}

@media only screen and (max-width: 56.25em), only screen and (hover: none) {
  #hero:has(.home__hero__right:hover) .home__hero__right__heading {
    font-size: 6rem;
  }
}

@media only screen and (max-width: 56.25em) and (max-width: 46.875em), only screen and (hover: none) and (max-width: 46.875em) {
  #hero:has(.home__hero__right:hover) .home__hero__right__heading {
    font-size: 5.5rem;
  }
}

@media only screen and (max-width: 56.25em) and (max-width: 37.5em), only screen and (hover: none) and (max-width: 37.5em) {
  #hero:has(.home__hero__right:hover) .home__hero__right__heading {
    font-size: 5rem;
  }
}

@media only screen and (max-width: 56.25em) and (max-width: 28.125em), only screen and (hover: none) and (max-width: 28.125em) {
  #hero:has(.home__hero__right:hover) .home__hero__right__heading {
    font-size: 4.5rem;
  }
}

@media only screen and (max-width: 56.25em) and (max-width: 21.875em), only screen and (hover: none) and (max-width: 21.875em) {
  #hero:has(.home__hero__right:hover) .home__hero__right__heading {
    font-size: 4rem;
  }
}

@media only screen and (max-width: 56.25em), only screen and (hover: none) {
  :where(.home__hero__left, .home__hero__right) {
    transition: all .3s;
  }

  .home__hero__right__heading__text, .home__hero__left__heading__text {
    width: auto;
    text-align: center;
    display: block;
  }

  .home__hero__right__heading__text:after, .home__hero__left__heading__text:after {
    content: "";
    width: 0;
    height: 3px;
    background: #d63031;
    transition: width .5s;
    display: block;
  }

  .home__hero__right__heading__text:hover:after, .home__hero__left__heading__text:hover:after {
    width: 100%;
  }
}

.how {
  padding: 6rem 1rem;
}

.how-box {
  grid-column-gap: 1rem;
  grid-template-columns: repeat(4, 1fr);
  display: grid;
}

.how__item {
  height: 45rem;
  background-position: center;
  background-size: cover;
  border-radius: 4px;
  padding: 3rem;
  transition: all .3s;
  overflow: hidden;
}

.how__item:hover .how__item__text-box {
  transform: translateY(1rem);
}

.how__item:hover .how__item__text-box__number, .how__item:hover .how__item__text-box__heading {
  color: #fbeaea;
}

.how__item:hover .how__item__text-box__text {
  transform: translateY(0);
}

.how__item--1 {
  background-image: linear-gradient(#fbeaea0d, #ab2627cc), url("how-1-2x.df305c3b.webp");
}

@media only screen and (min-resolution: 192dpi) and (min-width: 37.5em), only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 37.5em), only screen and (min-width: 125em) {
  .how__item--1 {
    background-image: linear-gradient(to bottom right, #fbeaea0d, #ab2627cc), url("how-1-3x.c54ebf2f.webp");
  }
}

.how__item--2 {
  background-image: linear-gradient(#fbeaea0d, #ab2627cc), url("how-2-2x.9aa7ceb2.webp");
}

@media only screen and (min-resolution: 192dpi) and (min-width: 37.5em), only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 37.5em), only screen and (min-width: 125em) {
  .how__item--2 {
    background-image: linear-gradient(to bottom right, #fbeaea0d, #ab2627cc), url("how-2-3x.5f0b8937.webp");
  }
}

.how__item--3 {
  background-image: linear-gradient(#fbeaea0d, #ab2627cc), url("how-3-2x.6f90e115.webp");
}

@media only screen and (min-resolution: 192dpi) and (min-width: 37.5em), only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 37.5em), only screen and (min-width: 125em) {
  .how__item--3 {
    background-image: linear-gradient(to bottom right, #fbeaea0d, #ab2627cc), url("how-3-3x.3dd73117.webp");
  }
}

.how__item--4 {
  background-image: linear-gradient(#fbeaea0d, #ab2627cc), url("how-4-2x.413faba7.webp");
}

@media only screen and (min-resolution: 192dpi) and (min-width: 37.5em), only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 37.5em), only screen and (min-width: 125em) {
  .how__item--4 {
    background-image: linear-gradient(to bottom right, #fbeaea0d, #ab2627cc), url("how-4-3x.40b0f9fd.webp");
  }
}

.how__item__text-box {
  flex-direction: column;
  gap: 3rem;
  transition: all .5s;
  display: flex;
  transform: translateY(20rem);
}

.how__item__text-box__number {
  color: #f3c1c1;
  font-size: 8rem;
  font-weight: 600;
  transition: all .3s;
}

.how__item__text-box__heading {
  color: #fbeaea;
  transition: all .3s;
}

.how__item__text-box__text {
  color: #f7d6d6;
  transition: all .3s;
  transform: translateY(10rem);
}

.how__item__text-box__text a, .how__item__text-box__text a:link, .how__item__text-box__text a:visited {
  color: #fbeaea;
  background-color: inherit;
  font-weight: 600;
  text-decoration: none;
}

@media only screen and (max-width: 56.25em), only screen and (hover: none) {
  .how-box {
    grid-gap: 2rem;
    grid-template-columns: repeat(2, 1fr);
    padding: 2rem;
    display: grid;
  }
}

@media only screen and (max-width: 56.25em) and (max-width: 37.5em), only screen and (hover: none) and (max-width: 37.5em) {
  .how-box {
    grid-gap: .5rem;
    padding: 0;
  }
}

@media only screen and (max-width: 56.25em) and (max-width: 28.125em), only screen and (hover: none) and (max-width: 28.125em) {
  .how-box {
    grid-template-columns: 1fr;
    padding: 0;
  }
}

@media only screen and (max-width: 56.25em), only screen and (hover: none) {
  .how__item {
    height: 50rem;
  }

  .how__item:hover .how__item__text-box {
    transform: translateY(1rem);
  }

  .how__item:hover .how__item__text-box__number {
    color: #400e0fb3;
  }

  .how__item:hover .how__item__text-box__heading {
    color: #400e0f;
  }

  .how__item:hover .how__item__text-box__text {
    transform: translateY(0);
  }

  .how__item--1 {
    background-image: linear-gradient(#fbeaeae6, #efacadcc), url("how-1-3x.c54ebf2f.webp");
  }

  .how__item--2 {
    background-image: linear-gradient(#fbeaeae6, #efacadcc), url("how-2-3x.5f0b8937.webp");
  }

  .how__item--3 {
    background-image: linear-gradient(#fbeaeae6, #efacadcc), url("how-3-3x.3dd73117.webp");
  }

  .how__item--4 {
    background-image: linear-gradient(#fbeaeae6, #efacadcc), url("how-4-3x.40b0f9fd.webp");
  }

  .how__item__text-box {
    transform: translateY(0);
  }

  .how__item__text-box__number {
    color: #400e0fb3;
  }

  .how__item__text-box__heading {
    color: #400e0f;
    transition: all .3s;
  }
}

@media only screen and (max-width: 56.25em) and (max-width: 28.125em), only screen and (hover: none) and (max-width: 28.125em) {
  .how__item__text-box__heading {
    font-size: 3rem;
  }
}

@media only screen and (max-width: 56.25em), only screen and (hover: none) {
  .how__item__text-box__text {
    color: #6b1819;
    font-weight: 400;
    transform: translateY(0);
  }
}

@media only screen and (max-width: 56.25em) and (max-width: 28.125em), only screen and (hover: none) and (max-width: 28.125em) {
  .how__item__text-box__text {
    font-size: 2rem;
  }
}

@media only screen and (max-width: 56.25em), only screen and (hover: none) {
  .how__item__text-box__text a, .how__item__text-box__text a:link, .how__item__text-box__text a:visited {
    color: #ab2627;
    background-color: inherit;
    font-weight: 600;
    text-decoration: none;
  }
}

.navigation__checkbox {
  display: none;
}

.navigation__button {
  height: 6rem;
  width: 6rem;
  z-index: 2000;
  text-align: center;
  cursor: pointer;
  background-color: #fbeaea;
  border-radius: 50%;
  position: fixed;
  top: 2rem;
  right: 2rem;
  box-shadow: 0 1rem 3rem #15050533;
}

@media only screen and (max-width: 56.25em) {
  .navigation__button {
    height: 5rem;
    width: 5rem;
    top: 1.5rem;
    right: 2rem;
  }
}

.navigation__background {
  height: 5rem;
  width: 5rem;
  z-index: 1000;
  background-image: radial-gradient(#de595a, #ab2627);
  border-radius: 50%;
  transition: transform .8s cubic-bezier(.86, 0, .07, 1);
  position: fixed;
  top: 2.5rem;
  right: 2.5rem;
}

@media only screen and (max-width: 56.25em) {
  .navigation__background {
    height: 4rem;
    width: 4rem;
    top: 1.5rem;
    right: 2.5rem;
  }
}

.navigation__nav {
  height: 100vh;
  z-index: 1500;
  visibility: hidden;
  opacity: 0;
  width: 0;
  transition: all .8s cubic-bezier(.68, -.55, .265, 1.55);
  position: fixed;
  top: 0;
  left: 0;
}

.navigation__list {
  text-align: center;
  width: 100%;
  list-style: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.navigation__item {
  margin: 1rem;
}

.navigation__link:link, .navigation__link:visited {
  color: #fbeaea;
  text-transform: uppercase;
  background-image: linear-gradient(120deg, #0000 0% 52%, #fbeaea 50%);
  background-size: 220%;
  padding: 1rem 2rem;
  font-size: 3rem;
  font-weight: 300;
  text-decoration: none;
  transition: all .4s;
  display: inline-block;
}

.navigation__link:link span, .navigation__link:visited span {
  margin-right: 1.5rem;
  display: inline-block;
}

.navigation__link:hover, .navigation__link:active {
  color: #d63031;
  background-position: 100%;
  transform: translateX(1rem);
}

.navigation__checkbox:checked ~ .navigation__background {
  transform: scale(80);
}

.navigation__checkbox:checked ~ .navigation__nav {
  visibility: visible;
  opacity: 1;
  width: 100%;
}

.navigation__icon {
  margin-top: 3rem;
  position: relative;
}

@media only screen and (max-width: 56.25em) {
  .navigation__icon {
    margin-top: 2.5rem;
  }
}

.navigation__icon, .navigation__icon:before, .navigation__icon:after {
  width: 3rem;
  height: 2px;
  background-color: #150505;
  display: inline-block;
}

.navigation__icon:before, .navigation__icon:after {
  content: "";
  transition: all .2s;
  position: absolute;
  left: 0;
}

.navigation__icon:before {
  top: -.8rem;
}

.navigation__icon:after {
  top: .8rem;
}

.navigation__button:hover .navigation__icon:before {
  top: -1rem;
}

.navigation__button:hover .navigation__icon:after {
  top: 1rem;
}

.navigation__checkbox:checked + .navigation__button .navigation__icon {
  background-color: #0000;
}

.navigation__checkbox:checked + .navigation__button .navigation__icon:before {
  top: 0;
  transform: rotate(135deg);
}

.navigation__checkbox:checked + .navigation__button .navigation__icon:after {
  top: 0;
  transform: rotate(-135deg);
}

.about-us {
  grid-row-gap: 10rem;
  grid-template-rows: calc(100vh - 10rem);
  grid-template-columns: 1fr;
  display: grid;
  overflow: hidden;
}

@media only screen and (max-width: 56.25em) {
  .about-us {
    grid-row-gap: 8rem;
    grid-template-rows: max-content;
  }
}

@media only screen and (max-width: 37.5em) {
  .about-us {
    grid-row-gap: 0rem;
  }
}

.about-us__hero {
  background-image: linear-gradient(0deg, #fbeaea4d 85%, #efacadcc 100%, #0000 100%);
  gap: 2rem;
  padding: 0 2rem 4rem;
  display: flex;
}

@media only screen and (max-width: 56.25em) {
  .about-us__hero {
    grid-template-rows: max-content max-content;
    grid-template-columns: 1fr;
    display: grid;
  }
}

@media only screen and (max-width: 37.5em) {
  .about-us__hero {
    gap: 0;
  }
}

@media only screen and (max-width: 71.875em) {
  .about-us__hero__heading {
    letter-spacing: 1px;
    font-size: 3.2rem;
  }
}

@media only screen and (max-width: 56.25em) {
  .about-us__hero__heading {
    font-size: 4rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .about-us__hero__heading {
    font-size: 3rem;
  }
}

.about-us__hero__img-box {
  grid-gap: 1rem;
  flex: 0 0 50%;
  grid-template-rows: repeat(6, 10vh);
  grid-template-columns: repeat(6, 1fr);
  padding: 1rem;
  display: grid;
}

@media only screen and (max-width: 56.25em) {
  .about-us__hero__img-box {
    height: 75rem;
    grid-row: 2 / 3;
    grid-template-rows: repeat(6, 1fr);
    grid-template-columns: repeat(6, 1fr);
    padding: 2rem;
  }
}

@media only screen and (max-width: 37.5em) {
  .about-us__hero__img-box {
    height: min-content;
    grid-template-rows: repeat(6, 10vw);
    grid-template-columns: repeat(6, 1fr);
  }
}

.about-us__hero__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
  transition: all .3s;
  display: block;
}

.about-us__hero__img:hover {
  transform: scale(1.05);
}

.about-us__hero__img--1 {
  grid-area: 1 / 1 / span 4 / span 2;
}

.about-us__hero__img--2 {
  grid-area: 1 / 5 / span 3 / span 2;
}

.about-us__hero__img--3 {
  grid-area: 4 / 5 / span 3 / span 2;
}

.about-us__hero__img--4 {
  grid-area: 5 / 1 / span 2 / span 4;
}

.about-us__hero__img--5 {
  grid-area: 1 / 3 / span 2 / span 2;
}

.about-us__hero__img--6 {
  grid-area: 3 / 3 / span 2 / span 2;
}

.about-us__hero__text-box {
  flex-direction: column;
  gap: 2rem;
  padding: 4rem;
  display: flex;
}

@media only screen and (max-width: 37.5em) {
  .about-us__hero__text-box {
    padding: 2rem;
  }
}

.about-us__hero__heading {
  color: #400e0f;
}

.about-us__hero__text {
  color: #801d1d;
}

@media only screen and (max-width: 37.5em) {
  .about-us__faq {
    margin-top: 4rem;
  }
}

.contact-us {
  background-image: linear-gradient(0deg, #fbeaea1a 85%, #efacadcc 100%, #0000 80%);
  justify-content: space-around;
  gap: 8rem;
  padding: 8rem 12rem;
  display: flex;
}

@media only screen and (max-width: 37.5em) {
  .contact-us {
    flex-direction: column;
    padding: 6rem 8rem 0;
  }
}

.contact-us__social {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  animation: .75s cubic-bezier(.25, .46, .45, .94) both slide-in-left;
  display: flex;
}

.contact-us__social__heading {
  color: #400e0f;
}

@media only screen and (max-width: 46.875em) {
  .contact-us__social__heading {
    font-size: 4rem;
  }
}

.contact-us__social__links {
  align-self: flex-start;
  align-items: center;
  gap: 4rem;
  list-style: none;
  display: flex;
}

@media only screen and (max-width: 37.5em) {
  .contact-us__social__links {
    align-self: center;
  }
}

.contact-us__social__links li {
  z-index: 99;
  fill: #d63031;
}

.contact-us__social__links__link:link, .contact-us__social__links__link:visited {
  fill: inherit;
  text-decoration: none;
  transition: all .3s;
  display: inline-block;
}

.contact-us__social__links__link:hover {
  fill: #ab2627;
  transform: scale(1.2);
}

.contact-us__social__links__link:active {
  transform: scale(1.05);
}

.contact-us__social__links__icon {
  height: 6rem;
  width: 6rem;
  fill: inherit;
}

.contact-us__contacts {
  color: #6b1819;
  flex-direction: column;
  justify-self: center;
  gap: 3rem;
  animation: .75s cubic-bezier(.25, .46, .45, .94) both slide-in-right;
  display: flex;
}

.contact-us__contacts__heading {
  color: #400e0f;
}

@media only screen and (max-width: 46.875em) {
  .contact-us__contacts__heading {
    font-size: 4rem;
  }
}

.contact-us__contacts__contact {
  flex-direction: column;
  gap: 2.4rem;
  font-size: 1.8rem;
  font-style: normal;
  line-height: 1.6;
  display: flex;
}

@media only screen and (max-width: 37.5em) {
  .contact-us__contacts__contact {
    justify-content: center;
    align-items: center;
  }
}

.contact-us__contacts__contact__link-box {
  width: max-content;
  display: inline-block;
}

.contact-us__contacts__contact__link:link, .contact-us__contacts__contact__link:visited {
  color: inherit;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  text-decoration: none;
  transition: all .5s;
  display: flex;
}

.contact-us__contacts__contact__link-box:after {
  content: "";
  width: 0;
  height: 2px;
  background: #d63031;
  transition: width .3s;
  display: block;
}

.contact-us__contacts__contact__link-box:hover:after {
  width: 100%;
}

.contact-us__contacts__contact__link__icon {
  height: 3rem;
  width: 3rem;
  fill: #d63031;
}

.gallery-pg {
  margin-bottom: 8rem;
}

.gallery-pg__hero {
  height: 100vh;
  background-image: linear-gradient(to left, #d6303199, #fbeaea80), url("hero-1-3x.8f10f11f.webp");
  background-position: center;
  background-size: cover;
  margin-bottom: 6rem;
}

.gallery-pg__hero__text-box {
  width: 80%;
  flex-direction: column;
  gap: 3rem;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.gallery-pg__hero__text-box h1 {
  animation: .7s cubic-bezier(.25, .46, .45, .94) backwards slide-in-bottom;
}

.gallery-pg__hero__btn {
  animation: .7s cubic-bezier(.25, .46, .45, .94) .7s backwards slide-in-bottom;
}

.gallery-pg__hero__heading {
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  display: inline-block;
}

.gallery-pg__header {
  background-image: linear-gradient(to left, #de595a, #ab2627);
  -webkit-background-clip: text;
  color: #0000;
  backface-visibility: hidden;
  display: inline-block;
}

.home__hero {
  height: calc(100vh - 10rem);
}

.siding {
  grid-row-gap: 10rem;
  grid-template-rows: 100vh;
  grid-template-columns: 1fr;
  display: grid;
  overflow: hidden;
}

.siding__hero {
  background-image: linear-gradient(to left, #d6303199, #fbeaea80), url("hero-1-2x.eb9f40e6.webp");
  background-position: center;
  background-size: cover;
}

@media only screen and (min-resolution: 192dpi) and (min-width: 37.5em), only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 37.5em), only screen and (min-width: 125em) {
  .siding__hero {
    background-image: linear-gradient(to left, #d6303199, #fbeaea80), url("hero-1-3x.4eff8da2.webp");
  }
}

.siding__hero__text-box {
  width: 80%;
  flex-direction: column;
  gap: 3rem;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.siding__hero__text-box h1 {
  animation: .7s cubic-bezier(.25, .46, .45, .94) backwards slide-in-bottom;
}

.siding__hero__btn {
  animation: .7s cubic-bezier(.25, .46, .45, .94) .7s backwards slide-in-bottom;
}

.siding__gallery a, .siding__gallery a:link, .siding__gallery a:visited {
  width: max-content;
  margin: 2rem auto;
  display: block;
}

.stone {
  grid-row-gap: 10rem;
  grid-template-rows: 100vh;
  grid-template-columns: 1fr;
  display: grid;
  overflow: hidden;
}

.stone__hero {
  background-image: linear-gradient(to left, #d6303199, #fbeaea80), url("hero-1-2x.fe67828a.webp");
  background-position: center;
  background-size: cover;
}

@media only screen and (min-resolution: 192dpi) and (min-width: 37.5em), only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 37.5em), only screen and (min-width: 125em) {
  .stone__hero {
    background-image: linear-gradient(to left, #d6303199, #fbeaea80), url("hero-1-3x.d8a693b5.webp");
  }
}

.stone__hero__text-box {
  width: 80%;
  flex-direction: column;
  gap: 3rem;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.stone__hero__text-box h1 {
  animation: .7s cubic-bezier(.25, .46, .45, .94) backwards slide-in-bottom;
}

.stone__hero__btn {
  animation: .7s cubic-bezier(.25, .46, .45, .94) .7s backwards slide-in-bottom;
}

.stone__gallery a, .stone__gallery a:link, .stone__gallery a:visited {
  width: max-content;
  margin: 2rem auto;
  display: block;
}

.success {
  height: 100vh;
  position: relative;
}

.success-box {
  z-index: 9999;
  width: 60rem;
  background-color: #de595a;
  border-radius: 4rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4rem;
  padding: 4rem;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.success-box--icon {
  width: 10rem;
  height: 10rem;
  fill: #de595a;
  background-color: #f7d6d6;
  border-radius: 50%;
  padding: 2rem;
}

.success-box__text {
  color: #f7d6d6;
  font-size: 2rem;
  line-height: 1.7;
}

.success-box__link:link, .success-box__link:visited {
  color: #d63031;
  background-color: #f7d6d6;
}

/*# sourceMappingURL=index.87c36709.css.map */
