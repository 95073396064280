@import 'abstracts/mixins';
@import 'abstracts/variables';

@import 'base/animations';
@import 'base/base';
@import 'base/typography';
@import 'base/utilities';

@import 'components/accordion';
@import 'components/button';
@import 'components/card';
@import 'components/composition';
@import 'components/cta';
@import 'components/featured-in';
@import 'components/features';
@import 'components/popup';
@import 'components/swiper';
@import 'components/swiper2';
@import 'components/tab';

@import 'layout/footer';
@import 'layout/header';
@import 'layout/hero';
@import 'layout/how';
@import 'layout/navigation';

@import 'pages/about-us';
@import 'pages/contact-us';
@import 'pages/gallery-pg';
@import 'pages/home';
@import 'pages/siding';
@import 'pages/stone';
@import 'pages/success';
